import { formatDate } from '@/core/lib/utils';
import Farm from '@/shared/models/farm';
import React from 'react';

interface FarmViewProps {
  farm: Farm;
  className?: string;
}

const FarmView: React.FC<FarmViewProps> = ({ farm, className }) => {
  return (
    <div
      className={`w-full border rounded-lg shadow-md p-6 mb-4 bg-white ${className}`}
    >
      <div className="flex items-center mb-4">
        <img
          src={farm.picture || '/icons/farm.svg'}
          alt={farm.label}
          className="w-16 h-16 rounded-full mr-4 object-cover"
        />
        <div>
          <h3 className="text-sm font-semibold">{farm.label}</h3>
          <div className="flex items-center gap-2">
            <span className="text-sm">Statut:</span>
            {farm.status === 'active' ? (
              <span className="w-3 h-3 bg-green-500 rounded-full inline-block" />
            ) : (
              <span className="w-3 h-3 bg-red-500 rounded-full inline-block" />
            )}
          </div>
        </div>
      </div>

      <table className="text-left text-sm w-auto">
        <tbody>
          <tr>
            <td className="font-semibold py-1 pr-4">Description:</td>
            <td>{farm.description || 'N/A'}</td>
          </tr>
        </tbody>
      </table>

      <div className="mt-2">
        <p className="mt-1 text-gray-500 text-xs">
          Créé le {formatDate(farm.createdAt)}
        </p>
        <p className="mt-1 text-gray-500 text-xs">
          Mis à jour le {formatDate(farm.updatedAt)}
        </p>
      </div>
    </div>
  );
};

export default FarmView;
