import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useFiles } from '../hooks/use-files';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { sanitize } from '../lib/utils';

interface RichTextEditorProps {
  content: string;
  onChange?: (content: string) => void;
  readOnly?: boolean;
}

interface DraftFile {
  data: {
    link: string;
  };
}
export default function RichTextEditor({
  content,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  readOnly,
}: RichTextEditorProps) {
  const { uploadFile } = useFiles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (content) {
      const blocksFromHTML = convertFromHTML(content);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [content]);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const newContent = editorState.getCurrentContent();
    const htmlContent = draftToHtml(convertToRaw(newContent));
    const sanitizedHtml = sanitize(htmlContent);
    onChange?.(sanitizedHtml.__html.toString());
  };

  const handleImageUpload = async (
    file: globalThis.File
  ): Promise<DraftFile> => {
    try {
      const imageUrl = await uploadFile(file, 'grant');
      const data = { link: imageUrl };
      return { data };
    } catch (error) {
      console.error('Error uploading image to S3:', error);
    }
  };

  const handleImageInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleImageUpload(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageInput} />
      <div className="editor">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName={`editor-wrapper ${readOnly ? '' : 'bg-white'}`}
          editorClassName="editor-main"
          uploadCallback={handleImageUpload}
          readOnly={readOnly}
          spellCheck={true}
          placeholder="Description de l'aide"
          toolbarHidden={readOnly}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </div>
    </div>
  );
}
