import React, { useCallback, useEffect } from 'react';
import CollapsibleMenu from './collapsible-menu';
import useRoles from '@/core/hooks/use-roles';
import { useCurrentPreferredRole, useUserActions } from '@/stores/slices/user';
import { useAdvisors } from '@/core/hooks/use-advisors';
import { useAdvisorOrganizations } from '@/core/hooks/use-advisor-organizations';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import {
  BookOpenText,
  CogIcon,
  HomeIcon,
  RefreshCwIcon,
  Tractor,
  UserIcon,
} from 'lucide-react';
import { ICON_SIZE } from '@/core/types/constants';

export interface NavigationItem {
  name: string;
  href: string;
  icon?: React.ReactNode;
  end?: boolean;
}

export default function PageLayout({
  children,
  className,
}: {
  readonly children: React.ReactNode;
  readonly className?: string;
}) {
  const { isLoading, loginWithRedirect, user, isAuthenticated } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        // redirect to previous page after login
        await loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
    })();
  }, [isLoading]);

  const { setRoles, setPreferredRole, setAdvisor, setAdvisorOrganizations } =
    useUserActions();
  const params = useParams();
  const { getPreferredRole } = useRoles();

  // Update roles & advisor & advisor organizations
  const { getMyAdvisor } = useAdvisors();
  const { getMyAdvisorOrganizations } = useAdvisorOrganizations();
  useEffect(() => {
    const fetch = async () => {
      const { roles, preferredRole } = await getPreferredRole();
      setRoles(roles);
      setPreferredRole(preferredRole);

      const advisor = await getMyAdvisor();
      if (advisor.value) {
        setAdvisor(advisor.value);
      }

      const advisorOrganizations = await getMyAdvisorOrganizations();
      if (advisorOrganizations.value) {
        setAdvisorOrganizations(advisorOrganizations.value);
      }
    };
    if (isAuthenticated) {
      fetch();
    }
  }, [isAuthenticated]);

  // Get role from store
  const role = useCurrentPreferredRole();

  const getTabs = useCallback(() => {
    switch (role) {
      case 'admin':
        return [
          {
            name: 'Accueil',
            href: '/admin',
            icon: <HomeIcon size={ICON_SIZE} />,
            end: true,
          },
          {
            name: "Annuaire d'aides",
            href: '/admin/grants',
            icon: <BookOpenText size={ICON_SIZE} />,
          },
          {
            name: 'Agriculteurs',
            href: '/admin/farmers',
            icon: <Tractor size={ICON_SIZE} />,
          },
          {
            name: 'Conseillers',
            href: '/admin/advisors',
            icon: <UserIcon size={ICON_SIZE} />,
          },
          {
            name: 'Rédacteurs',
            href: '/admin/maintainers',
            icon: <UserIcon size={ICON_SIZE} />,
          },
          {
            name: 'Paramètres',
            href: '/admin/settings',
            icon: <CogIcon size={ICON_SIZE} />,
          },
        ];
      case 'advisor':
        return [
          {
            name: 'Accueil',
            href: '/advisor',
            icon: <HomeIcon size={ICON_SIZE} />,
            end: true,
          },
          {
            name: 'Agriculteurs',
            href: '/advisor/farmers',
            icon: <Tractor size={ICON_SIZE} />,
          },

          {
            name: "Annuaire d'aides",
            href: '/advisor/grants',
            icon: <BookOpenText size={ICON_SIZE} />,
          },
        ];
      case 'farmer':
        return [
          {
            name: 'Accueil',
            href: '/farmer',
            icon: <HomeIcon size={ICON_SIZE} />,
            end: true,
          },

          {
            name: "Annuaire d'aides",
            href: '/farmer/grants',
            icon: <BookOpenText size={ICON_SIZE} />,
          },
          {
            name: 'Mon Exploitation',
            href: '/farmer/farm',
            icon: <Tractor size={ICON_SIZE} />,
          },
        ];
      case 'maintainer':
        return [
          {
            name: 'Accueil',
            href: '/maintainer',
            icon: <HomeIcon size={ICON_SIZE} />,
            end: true,
          },
          {
            name: "Annuaire d'aides",
            href: '/maintainer/grants',
            icon: <BookOpenText size={ICON_SIZE} />,
          },
          {
            name: 'Maintenance',
            href: '/maintainer/maintenance',
            icon: <RefreshCwIcon size={ICON_SIZE} />,
          },
          {
            name: 'Settings',
            href: '/maintainer/settings',
            icon: <CogIcon size={ICON_SIZE} />,
          },
        ];
      default:
        return [];
    }
  }, [role, params]);

  // Prevent flash of content
  if (!isAuthenticated) {
    return <div />;
  }

  return (
    <div>
      <CollapsibleMenu navigation={getTabs()} className={className}>
        {children}
      </CollapsibleMenu>
    </div>
  );
}
