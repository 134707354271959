import { usePaths } from '@/stores/slices/navigation';
import { NavLink, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const paths = usePaths();
  const location = useLocation();

  const activeLinkStyle = 'text-primary font-bold';
  return (
    <ol className="flex gap-x-2 mb-8">
      {paths.map((path, index) => (
        <li key={index} className="flex items-center">
          <NavLink
            to={path.url}
            className={`${location.pathname === path.url ? activeLinkStyle : ''} text-blue-600 hover:underline font-semibold flex items-center gap-x-2`}
          >
            {path.startIcon}
            {path.label}
            {path.endIcon}
          </NavLink>
          {index < paths.length - 1 && (
            <span className="mx-2 text-gray-400">{'>'}</span>
          )}
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumb;
