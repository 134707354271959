export default class PaginateParams {
  constructor(
    public search?: string,
    public sortBy?: string,
    public order?: number,
    public pageSize?: number,
    public pageNumber?: number,
    public columnFilters?: string,
    public columnOrders?: string,
    public include?: string
  ) {
    this.search = search;
    this.sortBy = sortBy ?? 'id';
    this.order = order ?? 1;
    this.pageSize = pageSize ?? 10;
    this.pageNumber = pageNumber ?? 0;
    this.columnFilters = columnFilters ?? '';
    this.columnOrders = columnOrders ?? '';
    this.include = include ?? '';
  }

  public toUrlParams(): URLSearchParams {
    const params = new URLSearchParams();
    if (this.search) params.append('search', this.search);
    if (this.sortBy) params.append('sortBy', this.sortBy);
    if (this.order) params.append('order', this.order.toString());
    if (this.pageSize) params.append('pageSize', this.pageSize.toString());
    if (this.pageNumber)
      params.append('pageNumber', this.pageNumber.toString());
    if (this.columnFilters) params.append('columnFilters', this.columnFilters);
    if (this.columnOrders) params.append('columnOrders', this.columnOrders);
    if (this.include) params.append('include', this.include);
    return params;
  }

  static fromUrl(urlParams: URLSearchParams): PaginateParams {
    const search = urlParams.get('search') ?? undefined;
    const sortBy = urlParams.get('sortBy') ?? undefined;
    const order = urlParams.get('order') ?? undefined;
    const pageSize = urlParams.get('pageSize') ?? undefined;
    const pageNumber = urlParams.get('pageNumber') ?? undefined;
    const columnFilters = urlParams.get('columnFilters') ?? undefined;
    const columnOrders = urlParams.get('columnOrders') ?? undefined;
    const include = urlParams.get('include') ?? undefined;
    return new PaginateParams(
      search,
      sortBy,
      order ? parseInt(order) : undefined,
      pageSize ? parseInt(pageSize) : undefined,
      pageNumber ? parseInt(pageNumber) : undefined,
      columnFilters,
      columnOrders,
      include
    );
  }

  public toUrl(): string {
    const url = this.toUrlParams().toString();
    return encodeURI(url);
  }

  public setPage(page: number) {
    this.pageNumber = page;
  }

  copy(params: PaginateParams): void {
    this.search = params.search;
    this.sortBy = params.sortBy;
    this.order = params.order;
    this.pageSize = params.pageSize;
    this.pageNumber = params.pageNumber;
    this.columnFilters = params.columnFilters;
    this.columnOrders = params.columnOrders;
    this.include = params.include;
  }
}
