import { useAuth0 } from '@auth0/auth0-react';
import apiService from '@/core/services/api.service';
import configurationService from '@/core/services/configuration.service';

export const useApi = () => {
  const { getIdTokenClaims } = useAuth0();

  const api = async (allowAnonymous = false) => {
    const configuration = configurationService.getConfiguration();
    const idToken = (await getIdTokenClaims())?.__raw;
    if (!idToken && !allowAnonymous) {
      throw new Error('Id token not found');
    }
    apiService.setToken(idToken);
    apiService.setApiURL(configuration.apiUri);
    return apiService;
  };

  return api;
};
