import FileInput from '@/core/components/controls/files/file-input';
import Farm from '@/shared/models/farm';
import { FarmerFormType } from '@/shared/schemas/farmer-schema';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

const FarmFields = ({
  index,
  values,
  register,
  setValue,
  errors,
  className,
}: {
  index: number;
  values: Farm;
  register: UseFormRegister<FarmerFormType>;
  setValue: UseFormSetValue<FarmerFormType>;
  errors: FieldErrors<FarmerFormType>;
  className?: string;
}) => {
  return (
    <div
      className={`w-full border rounded-lg shadow-md p-6 bg-white ${className}`}
    >
      <div className="w-full flex flex-col gap-4">
        <input
          type="hidden"
          {...register(`farms.${index}.id`, { value: values.id })}
        />

        {/* Farm header */}
        <div className="flex items-center">
          <div className="flex flex-col gap-2 flex-1">
            <div className="flex gap-2">
              <div className="flex items-start mb-4 gap-4 w-full">
                <div className="flex flex-col">
                  <input
                    type="hidden"
                    {...register(`farms.${index}.picture`, {
                      value: values.picture,
                    })}
                  />
                  <FileInput
                    name={`farm-picture-${index}`}
                    className="!h-[88px] !w-[88px] rounded-full"
                    defaultValue={values.picture}
                    folder="farms"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setValue(`farms.${index}.picture`, e.target.value);
                    }}
                  />
                  {errors?.farms?.[index].picture && (
                    <span className="text-red-500 text-sm">
                      {errors?.farms?.[index].picture.message}
                    </span>
                  )}
                </div>

                <div className="w-full flex flex-col gap-2">
                  <input
                    id="label"
                    {...register(`farms.${index}.label`)}
                    defaultValue={values.label}
                    placeholder="Nom de la ferme"
                    className="border border-gray-300 rounded-md p-2 w-full"
                  />
                  {errors?.farms?.[index].label && (
                    <span className="text-red-500 text-sm">
                      {errors?.farms?.[index].label.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex flex-col gap-2 w-1/2">
                <input
                  id="addressStreet"
                  {...register(`farms.${index}.addressStreet`)}
                  defaultValue={values.addressStreet}
                  placeholder="Adresse"
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
                {errors?.farms?.[index].addressStreet && (
                  <span className="text-red-500 text-sm">
                    {errors?.farms?.[index].addressStreet.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <input
                  id="addressCity"
                  {...register(`farms.${index}.addressCity`)}
                  defaultValue={values.addressCity}
                  placeholder="Ville"
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
                {errors?.farms?.[index].addressCity && (
                  <span className="text-red-500 text-sm">
                    {errors?.farms?.[index].addressCity.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col gap-2 w-1/2">
                <input
                  id="addressZipCode"
                  {...register(`farms.${index}.addressZipCode`)}
                  defaultValue={values.addressZipCode}
                  placeholder="Code postal"
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
                {errors?.farms?.[index].addressZipCode && (
                  <span className="text-red-500 text-sm">
                    {errors?.farms?.[index].addressZipCode.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <input
                  id="addressCountry"
                  {...register(`farms.${index}.addressCountry`)}
                  defaultValue={values.addressCountry}
                  placeholder="Pays"
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
                {errors?.farms?.[index].addressCountry && (
                  <span className="text-red-500 text-sm">
                    {errors?.farms?.[index].addressCountry.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmFields;
