import { Fragment, useEffect, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Select,
  Transition,
} from '@headlessui/react';
import { useAdvisorOrganizations } from '@/core/hooks/use-advisor-organizations';
import AdvisorOrganization from '@/shared/models/advisor-organization';
import {
  useCurrentAdvisorOrganization,
  useUserActions,
} from '@/stores/slices/user';
import { Check } from 'lucide-react';
import { isStringNullOrEmpty } from '@/core/lib/utils';

const Organizations = () => {
  const [organizations, setOrganizations] = useState<AdvisorOrganization[]>([]);
  const { getMyAdvisorOrganizations } = useAdvisorOrganizations();
  const organization = useCurrentAdvisorOrganization();
  const { setAdvisorOrganization } = useUserActions();

  useEffect(() => {
    (async () => {
      const data = await getMyAdvisorOrganizations();
      setOrganizations(data.value || []);
    })();
  }, []);

  useEffect(() => {
    if (!organizations) {
      return;
    }
    const organizationIdLocalStorage = localStorage.getItem('organization_id');
    if (!isStringNullOrEmpty(organizationIdLocalStorage)) {
      const organizationLocalStorage = organizations.find(
        (t) => t.id === organizationIdLocalStorage
      );
      if (organizationLocalStorage) {
        handleOrganization(organizationLocalStorage);
        return;
      }
      handleOrganization(organizations[0]);
      return;
    }
    handleOrganization(organizations[0]);
  }, [organizations]);

  const handleOrganization = (selectedOrganization?: AdvisorOrganization) => {
    setAdvisorOrganization(selectedOrganization);
    localStorage.setItem('organization_id', selectedOrganization?.id || '');
  };

  return (
    <div className="relative w-full h-full">
      <Listbox value={organization || null} onChange={handleOrganization}>
        <div className="relative h-full">
          <ListboxButton className="hover:bg-gray-100 h-full flex gap-2 items-center space-x-2 px-3 text-xs font-medium text-light-grey cursor-pointer">
            <span
              className={`h-6 w-6 flex items-center justify-center rounded-full bg-accent text-white font-bold transition-opacity duration-500 ease-in-out ${
                organization?.label ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {organization?.label.charAt(0)}
            </span>
            <span className="hidden sm:block">{organization?.label}</span>
            <Select />
          </ListboxButton>
          <Transition
            as={Fragment}
            leave="transition ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute mt-[-0.25   em] top-full left-3 z-10 w-30 max-h-80 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:text-sm">
              {organizations.map((organization) => (
                <ListboxOption
                  as="div"
                  key={organization.id}
                  className={({ focus }) =>
                    `relative select-none py-2 px-3 text-gray-900 ${focus && 'bg-lighter-grey'}`
                  }
                  value={organization}
                >
                  {({ selected }) => (
                    <div className="cursor-pointer flex items-center space-x-2">
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                      >
                        {organization.label}
                      </span>
                      {selected ? (
                        <Check
                          className="h-5 w-5 text-accent ml-auto"
                          aria-hidden="true"
                        />
                      ) : null}
                    </div>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Organizations;
