import TruncatedText from '@/core/components/truncated-text';
import { formatLocaleDate } from '@/core/lib/utils';
import Grant from '@/shared/models/grant';
import { useCurrentPreferredRole } from '@/stores/slices/user';
import { EyeIcon } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';

interface Props {
  grant: Grant;
}

// grant item
const Index = ({ grant }: Props) => {
  const navigate = useNavigate();
  const role = useCurrentPreferredRole();

  return (
    <div className="flex flex-col bg-white shadow-md rounded-lg p-4 relative">
      <button
        className="absolute top-4 right-4 p-2 cursor-pointer z-10"
        onClick={() => navigate(`/${role}/grants/${grant.id}`)}
      >
        <div className="p-2 rounded-full bg-accent hover:bg-accent-foreground text-primary-foreground">
          <EyeIcon size={16} />
        </div>
      </button>
      <NavLink
        className="grant-header text-xl font-bold pr-[2.95rem] mr-auto underline underline-offset-[0.1rem] hover:no-underline"
        to={`/${role}/grants/${grant.id}`}
      >
        {grant.label}
      </NavLink>
      <div className="grant-description w-[90%] text-justify">
        <TruncatedText html={grant.description} n={2} />
      </div>

      <div className="grant-footer mx-[5%] mt-4 flex flex-row flex-wrap justify-start items-start gap-4">
        <div className="flex flex-row flex-wrap">
          <div className="grant-tags flex flex-col">
            <p className="text-accent mb-2">Tags</p>
            <div className="flex flex-row gap-2 flex-wrap w-[200px]">
              {grant.grantTags?.map((tag) => (
                <div
                  key={tag.id}
                  className="grant-tag text-xs bg-gray-200 rounded-lg p-1"
                >
                  {tag.label}
                </div>
              ))}
            </div>
          </div>

          <div className="grant-types flex flex-col">
            <p className="text-accent mb-2">Types</p>
            <div className="flex flex-row gap-2 flex-wrap w-[200px]">
              {grant.grantTypeGrants?.map((grantType) => (
                <div
                  key={grantType.id}
                  className="grant-type text-xs bg-gray-200 rounded-lg p-1"
                >
                  {grantType.label}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="grant-localizations flex flex-col">
            <p className="text-accent mb-2">Localisations</p>
            <div className="flex flex-row gap-2 flex-wrap w-[200px]">
              {grant.grantLocalizations?.map((localization) => (
                <div
                  key={localization.id}
                  className="grant-localization text-xs bg-gray-200 rounded-lg p-1"
                >
                  {localization.label}
                </div>
              ))}
            </div>
          </div>

          <div className="grant-submission-deadline flex flex-col">
            <p className="text-accent">Date limite</p>
            <p>{formatLocaleDate(grant.submissionDeadline) ?? 'N/A'}</p>
          </div>

          {grant.maxBudget && (
            <div className="grant-submission-max-budget flex flex-col">
              <p className="text-accent">Budget maximum</p>
              <p>{grant.maxBudget}€</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
