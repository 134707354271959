import CallbackPage from '@/pages/callback-page';
import NotFoundPage from '@/pages/not-found-page';
import ProfilePage from './pages/profile-page';
import { AuthenticationGuard } from './core/guards/authentication-guard';
import Grants from './pages/grants/list';
import GrantView from './pages/grants/item';
import Unauthorized from './pages/unauthorized';
import Dashboard from './pages/dashboard';
import AdvisorDashboard from './pages/role-based/advisor';
import AdminDashboard from './pages/role-based/admin';
import FarmerDashboard from './pages/role-based/farmer';
import MaintainerDashboard from './pages/role-based/maintainer';
import AdminFarmers from './pages/role-based/admin/farmers';
import AdminAdvisors from './pages/role-based/admin/advisors';
import AdminMaintainers from './pages/role-based/admin/maintainers';
import AdminSettings from './pages/role-based/admin/settings';
import AdvisorFarmers from './pages/role-based/advisor/farmers/list';
import AdvisorFarmer from './pages/role-based/advisor/farmers/item';
import FarmerFarm from './pages/role-based/farmer/farm';
import {
  BookOpenText,
  BuildingIcon,
  CogIcon,
  HomeIcon,
  Tractor,
  UserIcon,
  UserRoundPen,
} from 'lucide-react';
import { IRouteObject } from './core/types/IRouteObject';
import { ICON_SIZE } from './core/types/constants';

const routesConfig: IRouteObject[] = [
  {
    index: true,
    element: <AuthenticationGuard component={Dashboard} />,
  },
  {
    path: '/advisor',
    children: [
      {
        index: true,
        element: (
          <AuthenticationGuard component={AdvisorDashboard} roles={'advisor'} />
        ),
        label: 'Accueil',
        startIcon: <HomeIcon size={ICON_SIZE} />,
      },
      {
        path: 'farmers',
        element: (
          <AuthenticationGuard component={AdvisorFarmers} roles={'advisor'} />
        ),
        label: 'Agriculteurs',
        startIcon: <Tractor size={ICON_SIZE} />,
      },
      {
        path: 'farmers/:id',
        element: (
          <AuthenticationGuard component={AdvisorFarmer} roles={'advisor'} />
        ),
        dynamic: true,
        startIcon: <UserIcon size={ICON_SIZE} />,
      },
    ],
  },
  {
    path: '/admin',
    children: [
      {
        index: true,
        element: (
          <AuthenticationGuard component={AdminDashboard} roles={'admin'} />
        ),
        label: 'Accueil',
        startIcon: <HomeIcon size={ICON_SIZE} />,
      },
      {
        path: 'farmers',
        element: (
          <AuthenticationGuard component={AdminFarmers} roles={'admin'} />
        ),
        label: 'Agriculteurs',
        startIcon: <Tractor size={ICON_SIZE} />,
      },
      {
        path: 'advisors',
        element: (
          <AuthenticationGuard component={AdminAdvisors} roles={'admin'} />
        ),
        label: 'Conseillers',
        startIcon: <UserIcon size={ICON_SIZE} />,
      },
      {
        path: 'maintainers',
        element: (
          <AuthenticationGuard component={AdminMaintainers} roles={'admin'} />
        ),
        label: "Rédacteurs d'aide",
        startIcon: <UserIcon size={ICON_SIZE} />,
      },
      {
        path: 'settings',
        element: (
          <AuthenticationGuard component={AdminSettings} roles={'admin'} />
        ),
        label: 'Paramètres',
        startIcon: <CogIcon size={ICON_SIZE} />,
      },
    ],
  },
  {
    path: '/farmer',
    children: [
      {
        path: '',
        element: (
          <AuthenticationGuard component={FarmerDashboard} roles={'farmer'} />
        ),
        label: 'Accueil',
        startIcon: <Tractor size={ICON_SIZE} />,
      },
      {
        path: 'farm',
        element: (
          <AuthenticationGuard component={FarmerFarm} roles={'farmer'} />
        ),
        label: 'Mon exploitation',
        startIcon: <BuildingIcon size={ICON_SIZE} />,
      },
    ],
  },
  {
    path: '/maintainer',
    element: (
      <AuthenticationGuard
        component={MaintainerDashboard}
        roles={'maintainer'}
      />
    ),
    label: 'Accueil',
    startIcon: <HomeIcon size={ICON_SIZE} />,
  },
  {
    path: '/callback',
    element: <CallbackPage />,
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />,
  },
  {
    path: '/:role',
    children: [
      {
        path: 'profile',
        element: <AuthenticationGuard component={ProfilePage} />,
        label: 'Profil',
        startIcon: <UserRoundPen size={ICON_SIZE} />,
      },
      {
        path: 'grants',
        element: <AuthenticationGuard component={Grants} />,
        label: 'Aides',
        startIcon: <BookOpenText size={ICON_SIZE} />,
      },
      {
        path: 'grants/:id',
        element: (
          <AuthenticationGuard
            component={GrantView}
            roles={['admin', 'maintainer', 'farmer', 'advisor']}
          />
        ),
        dynamic: true,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

function defineAbsolutePaths(
  routes: IRouteObject[],
  parentPath: string = ''
): IRouteObject[] {
  return routes.map((route) => {
    const absolutePath = route.index
      ? parentPath // Index route, inherits parent's path
      : `${parentPath}/${route.path || ''}`.replace(/\/+/g, '/'); // Concatenate and remove duplicate slashes

    const updatedRoute: IRouteObject = {
      ...route,
      absolutePath,
    };

    if (route.children) {
      updatedRoute.children = defineAbsolutePaths(route.children, absolutePath);
    }

    return updatedRoute;
  });
}

const routes = defineAbsolutePaths(routesConfig);
const flattenedRoutes: IRouteObject[] = routes.reduce(
  (acc: IRouteObject[], route: IRouteObject) => {
    if (route.children) {
      return [...acc, ...route.children];
    }
    return [...acc, route];
  },
  []
);
export { routes, flattenedRoutes };
