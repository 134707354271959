import Farmer from '@/core/../shared/models/farmer';
import FarmerParams from '@/core/types/farmerParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';
import { DeepPartial } from 'react-hook-form';

export const useFarmers = () => {
  const getApi = useApi();

  const searchFarmers = async (params?: FarmerParams) => {
    const api = await getApi();

    let url = '/farmers/search';
    if (params) {
      const filtersObj = new FarmerParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }

    const data = await api.get<RepoResult<Pagination<Farmer>>>(url);
    return data;
  };

  const getFarmer = async (id: string, params?: FarmerParams) => {
    const api = await getApi();

    let url = '/farmers/' + id;
    if (params) {
      const filtersObj = new FarmerParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }
    return await api.get<RepoResult<Farmer>>(url);
  };

  const createFarmer = async (farmer: {
    email: string;
    advisorOrganizationId?: string;
    isAdmin?: boolean;
  }) => {
    const api = await getApi();
    return await api.post<
      { email: string; advisorOrganizationId?: string; isAdmin?: boolean },
      RepoResult<Farmer>
    >('/farmers', JSON.stringify(farmer));
  };

  const updateFarmer = async (farmer: Farmer) => {
    const api = await getApi();
    return await api.put<Farmer, RepoResult<Farmer>>(
      '/farmers/' + farmer.id,
      JSON.stringify(farmer)
    );
  };

  const patchFarmer = async (farmer: DeepPartial<Farmer>) => {
    const api = await getApi();
    return await api.patch<Partial<Farmer>, RepoResult<Farmer>>(
      '/farmers/' + farmer.id,
      JSON.stringify(farmer)
    );
  };

  const deleteFarmer = async (id: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/farmers/' + id);
  };

  const deleteFarmers = async (ids: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/farmers/delete/' + ids);
  };

  return {
    searchFarmers,
    getFarmer,
    createFarmer,
    updateFarmer,
    patchFarmer,
    deleteFarmer,
    deleteFarmers,
  };
};
