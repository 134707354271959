import PageLoader from '@/core/components/page-loader';

const Index = () => {
  return (
    <>
      <h1>Mon Exploitation</h1>
      <PageLoader />
    </>
  );
};

export default Index;
