import Advisor from '@/core/../shared/models/advisor';
import PaginateParams from '@/core/types/paginateParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';

export const useAdvisors = () => {
  const getApi = useApi();

  const searchAdvisors = async (params?: PaginateParams) => {
    const api = await getApi();

    let url = '/advisors/search';
    if (params) {
      const filtersObj = new PaginateParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }

    const data = await api.get<RepoResult<Pagination<Advisor>>>(url);
    return data;
  };

  const getMyAdvisor = async () => {
    const api = await getApi();
    return await api.get<RepoResult<Advisor>>('/advisors/me');
  };

  const getAdvisor = async (id: string, params?: PaginateParams) => {
    const api = await getApi();

    let url = '/advisors/' + id;
    if (params) {
      const filtersObj = new PaginateParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }
    return await api.get<RepoResult<Advisor>>(url);
  };

  const createAdvisor = async (advisor: {
    email: string;
    advisorOrganizationId?: string;
    isAdmin?: boolean;
  }) => {
    const api = await getApi();
    return await api.post<
      { email: string; advisorOrganizationId?: string; isAdmin?: boolean },
      RepoResult<Advisor>
    >('/advisors', JSON.stringify(advisor));
  };

  const updateAdvisor = async (advisor: Advisor) => {
    const api = await getApi();
    return await api.put<Advisor, RepoResult<Advisor>>(
      '/advisors/' + advisor.id,
      JSON.stringify(advisor)
    );
  };

  const patchAdvisor = async (advisor: Partial<Advisor>) => {
    const api = await getApi();
    return await api.patch<Partial<Advisor>, RepoResult<Advisor>>(
      '/advisors/' + advisor.id,
      JSON.stringify(advisor)
    );
  };

  const deleteAdvisor = async (id: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/advisors/' + id);
  };

  const deleteAdvisors = async (ids: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/advisors/delete/' + ids);
  };

  return {
    searchAdvisors,
    getMyAdvisor,
    getAdvisor,
    createAdvisor,
    updateAdvisor,
    patchAdvisor,
    deleteAdvisor,
    deleteAdvisors,
  };
};
