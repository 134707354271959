import { useEffect, useState } from 'react';
import Modal, { ModalProps } from './modal';

interface ConfirmModalProps extends ModalProps {
  message: string;
  cancelLabel?: string;
  confirmLabel?: string;
  closeOnCancel?: boolean;
  closeOnConfirm?: boolean;
  onCancel?: () => void;
  onConfirm?: (event: React.FormEvent) => void;
  loading?: boolean;
}

export default function ConfirmModal({
  message,
  cancelLabel = 'Annuler',
  confirmLabel = 'Confirmer',
  closeOnCancel = true,
  closeOnConfirm = true,
  onCancel,
  onConfirm,
  loading = false,

  // Modal props
  isOpen,
  children,
  className = 'w-[calc(100%-10px)] sm:w-[calc(100%-2rem)] max-w-[30rem]',
  innerClassName = 'w-full max-h-screen',
}: Readonly<ConfirmModalProps>) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCancel = () => {
    onCancel?.();
    if (closeOnCancel) setOpen(false);
  };

  const handleConfirm = (e: React.FormEvent) => {
    onConfirm?.(e);
    if (closeOnConfirm) setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleCancel}
      className={className}
      innerClassName={innerClassName}
    >
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform rounded-2xl bg-white px-0 pt-6 pb-2 text-left align-middle shadow-xl transition-all">
        <span className="px-4 text-center text-[16px] font-bold mb-4">
          {message}
        </span>
        <form
          className="flex-1 flex flex-col gap-2 overflow-y-hidden"
          onSubmit={handleConfirm}
        >
          <div className="flex-1 overflow-y-auto px-[10px] sm:px-8">
            {children}
          </div>

          <hr className="border-gray-300 mt-4" />

          <div className="flex justify-end gap-4 px-4">
            <button
              type="button"
              className="text-xs font-semibold bg-white text-black py-2 px-4 rounded-lg border-solid border-2 border-black hover:border-destructive hover:text-destructive"
              onClick={handleCancel}
              disabled={loading}
            >
              {cancelLabel}
            </button>
            <button
              type="submit"
              className="text-xs font-semibold bg-black text-white py-2 px-4 rounded-lg hover:bg-accent"
              disabled={loading}
            >
              {confirmLabel}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
