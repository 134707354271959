import PaginateParams from '@/core/types/paginateParams';
import { Search } from 'lucide-react';

export default function SearchBar({
  displayIcon = true,
  search,
  setFilter,
  placeholder = 'Rechercher...',
  className = '',
}) {
  const handleChange = (e) => {
    e.persist();
    setFilter((f: PaginateParams) => ({
      ...f,
      search: e.target.value,
      page: 0,
    }));
  };
  return (
    <div
      className={`${className} relative rounded-md border border-gray-300 shadow-sm w-full min-w-[160px] max-w-[400px]`}
    >
      {displayIcon && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )}
      <input
        type="text"
        name="search"
        defaultValue={search}
        onChange={handleChange}
        className={`${displayIcon ? '!pl-10' : '!pl-2'} block w-full h-10 rounded-md border-2 focus:outline-none border-transparent focus:border-accent md:text-sm`}
        placeholder={placeholder}
      />
    </div>
  );
}
