const Loader = ({ size }: { size: string }) => {
  if (size === 'small') {
    return (
      <div className="flex items-center justify-center space-x-2">
        <div
          className="border-l-primary animate-spin text-[#0560FD] inline-block h-4 w-4 min-h-4 min-w-4 border-2 rounded-full"
          role="status"
        >
          <span className="hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center justify-center space-x-2 m-20">
      <div
        className="border-l-primary animate-spin text-[#0560FD] inline-block h-16 w-16 min-h-16 min-w-16 border-[6px] rounded-full"
        role="status"
      >
        <span className="hidden">Loading...</span>
      </div>
    </div>
  );
};
export default Loader;
