import * as Yup from 'yup';

export const farmSchema = Yup.object().shape({
  id: Yup.string(),
  label: Yup.string()
    .required("Le nom de l'exploitation est requis")
    .min(2, "Le nom de l'exploitation doit contenir au moins 2 caractères"),
  picture: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .url("L'URL de l'image est invalide"),
  description: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  addressStreet: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  addressCity: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  addressZipCode: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .matches(/^(\d{5}|)$/, 'Le code postal est invalide'),
  addressCountry: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
});
export type FarmFormType = Yup.InferType<typeof farmSchema>;
