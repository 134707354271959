import * as Yup from 'yup';
import { userSchema } from './user-schema';
import { farmSchema } from './farm-schema';

export const farmerSchema = Yup.object().shape({
  id: Yup.string(),
  user: userSchema,
  farms: Yup.array().of(farmSchema),
});
export type FarmerFormType = Yup.InferType<typeof farmerSchema>;
