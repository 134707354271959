import AdvisorOrganization from '@/core/../shared/models/advisor-organization';
import PaginateParams from '@/core/types/paginateParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';

export const useAdvisorOrganizations = () => {
  const getApi = useApi();

  const getMyAdvisorOrganizations = async () => {
    const api = await getApi();
    return await api.get<RepoResult<AdvisorOrganization[]>>(
      '/advisor-organizations/me'
    );
  };

  const searchAdvisorOrganizations = async (params?: PaginateParams) => {
    const api = await getApi();

    let url = '/advisor-organizations/search';
    if (params) {
      const filtersObj = new PaginateParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }

    const data =
      await api.get<RepoResult<Pagination<AdvisorOrganization>>>(url);
    return data;
  };

  const getAdvisorOrganization = async (
    id: string,
    params?: PaginateParams
  ) => {
    const api = await getApi();

    let url = '/advisor-organizations/' + id;
    if (params) {
      const filtersObj = new PaginateParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }
    return await api.get<RepoResult<AdvisorOrganization>>(url);
  };

  const createAdvisorOrganization = async (advisorOrganization: {
    email: string;
    advisorOrganizationOrganizationId?: string;
    isAdmin?: boolean;
  }) => {
    const api = await getApi();
    return await api.post<
      {
        email: string;
        advisorOrganizationOrganizationId?: string;
        isAdmin?: boolean;
      },
      RepoResult<AdvisorOrganization>
    >('/advisor-organizations', JSON.stringify(advisorOrganization));
  };

  const updateAdvisorOrganization = async (
    advisorOrganization: AdvisorOrganization
  ) => {
    const api = await getApi();
    return await api.put<AdvisorOrganization, RepoResult<AdvisorOrganization>>(
      '/advisor-organizations/' + advisorOrganization.id,
      JSON.stringify(advisorOrganization)
    );
  };

  const patchAdvisorOrganization = async (
    advisorOrganization: Partial<AdvisorOrganization>
  ) => {
    const api = await getApi();
    return await api.patch<
      Partial<AdvisorOrganization>,
      RepoResult<AdvisorOrganization>
    >(
      '/advisor-organizations/' + advisorOrganization.id,
      JSON.stringify(advisorOrganization)
    );
  };

  const deleteAdvisorOrganization = async (id: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/advisor-organizations/' + id);
  };

  const deleteAdvisorOrganizations = async (ids: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>(
      '/advisor-organizations/delete/' + ids
    );
  };

  return {
    getMyAdvisorOrganizations,
    searchAdvisorOrganizations,
    getAdvisorOrganization,
    createAdvisorOrganization,
    updateAdvisorOrganization,
    patchAdvisorOrganization,
    deleteAdvisorOrganization,
    deleteAdvisorOrganizations,
  };
};
