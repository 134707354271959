import { useState } from 'react';
import Grant from '@/shared/models/grant';
import { ChevronUp, ChevronDown } from 'lucide-react';
import RichTextEditor from '@/core/components/rich-text-editor';

const View = ({ values }: { values: Grant }) => {
  const [expandDescription, setExpandDescription] = useState(true);
  return (
    <div className="grant-details">
      <div className="grant-header text-xl font-bold mb-2">{values.label}</div>

      <div className="flex flex-wrap gap-12">
        <div>
          <p className="text-accent mb-2">Tags</p>
          <div className="flex flex-row gap-2 flex-wrap">
            {values.grantTags?.map((tag) => (
              <span
                key={tag.id}
                className="grant-tag text-xs bg-gray-200 rounded-lg p-1"
              >
                {tag.label}
              </span>
            )) || <div>N/A</div>}
          </div>
        </div>

        <div>
          <p className="text-accent mb-2">Localisations</p>
          <div className="flex flex-row gap-2 flex-wrap">
            {values.grantLocalizations?.map((localization) => (
              <span
                key={localization.id}
                className="grant-localization text-xs bg-gray-200 rounded-lg p-1"
              >
                {localization.label}
              </span>
            )) || <div>N/A</div>}
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-row gap-4">
        <button
          className="toggle-icon"
          onClick={() => setExpandDescription(!expandDescription)}
        >
          {expandDescription ? <ChevronUp /> : <ChevronDown />}
        </button>
        <h2 className="text-md font-bold">Description</h2>
      </div>

      {expandDescription && (
        <div className="grant-description mt-2">
          <RichTextEditor content={values.description} readOnly={true} />
        </div>
      )}
    </div>
  );
};

export default View;
