import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { EllipsisIcon } from 'lucide-react';
import { ReactElement } from 'react';

export interface MenuAction {
  label?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  callback?: () => void;
  disabled?: boolean;
}

interface ActionsMenuProps {
  float?: 'left' | 'right';
  actions: MenuAction[];
  label?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  actionsIconSize?: number;
  actionsIconColor?: string;
}
const ActionsMenu = ({
  float = 'left',
  actions,
  label,
  startIcon = <EllipsisIcon size={18} className="margin-0 padding-0" />,
  endIcon,
}: ActionsMenuProps) => {
  return (
    <Menu as="div" className="flex gap-2 p-2 relative items-center">
      <MenuButton
        className="p-2 rounded-full bg-accent hover:bg-accent-foreground text-white
        cursor-pointer data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
      >
        {startIcon}
        <span>{label}</span>
        {endIcon}
      </MenuButton>
      <MenuItems
        transition
        className={`p-0 m-0 ${float === 'right' ? '' : 'transform -translate-x-[73.5%]'}
          absolute top-[100%] left-0 flex flex-col
          rounded overflow-hidden z-10 bg-[#fff]
          transition duration-100 ease-in shadow-menuShadow
          data-[closed]:scale-95 data-[closed]:opacity-0`}
      >
        {actions &&
          Array.isArray(actions) &&
          actions.length > 0 &&
          actions.map((action) => (
            <MenuItem key={action.label}>
              <button
                className={`flex items-center text-sm gap-4 w-full rounded-md px-6 py-[10px]
                  ${action.disabled ? 'cursor-default text-gray-400' : 'cursor-pointer hover:text-accent'}`}
                onClick={action.callback}
                disabled={action.disabled}
              >
                {action.startIcon}
                <span>{action.label}</span>
                {action.endIcon}
              </button>
            </MenuItem>
          ))}
      </MenuItems>
    </Menu>
  );
};
export default ActionsMenu;
