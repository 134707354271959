import {
  ChevronFirstIcon,
  ChevronLast,
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'lucide-react';

const PAGINATION_SIZE = 4; // Number of pages to show in the paginator
interface PaginatorProps {
  page: number;
  setPage: (page: number) => void;
  last?: number;
  className?: string;
  zeroIndexed?: boolean; // default is false
  isLoading?: boolean; // default is false
}

const Paginator = ({
  page,
  setPage,
  last = 0,
  className,
  zeroIndexed = false, // default to false
  isLoading = false, // default to false
}: PaginatorProps) => {
  const pages = Array.from({ length: last }, (_, i) =>
    zeroIndexed ? i : i + 1
  );

  const loadingStyle = isLoading ? 'opacity-50 pointer-events-none' : '';

  return (
    <div
      className={`${className} ${loadingStyle} flex flex-row items-center justify-center gap-1`}
    >
      <FirstPageButton
        page={page}
        setPage={setPage}
        zeroIndexed={zeroIndexed}
        isLoading={isLoading}
      />
      <PrevPageButton
        page={page}
        setPage={setPage}
        zeroIndexed={zeroIndexed}
        isLoading={isLoading}
      />
      <div className="w-72 flex items-center justify-center">
        <PageRange
          pages={pages}
          currentPage={page}
          setPage={setPage}
          last={last}
          zeroIndexed={zeroIndexed}
          isLoading={isLoading}
        />
      </div>
      <NextPageButton
        page={page}
        setPage={setPage}
        last={last}
        zeroIndexed={zeroIndexed}
        isLoading={isLoading}
      />
      <LastPageButton
        page={page}
        setPage={setPage}
        last={last}
        zeroIndexed={zeroIndexed}
        isLoading={isLoading}
      />
    </div>
  );
};

const PageRange = ({
  pages,
  currentPage,
  setPage,
  last,
  zeroIndexed,
  isLoading,
}: {
  pages: number[];
  currentPage: number;
  setPage: (page: number) => void;
  last: number;
  zeroIndexed: boolean;
  isLoading: boolean;
}) => {
  const loadingStyle = isLoading ? 'opacity-50 pointer-events-none' : '';

  if (last > PAGINATION_SIZE + 1) {
    if (currentPage < PAGINATION_SIZE) {
      return (
        <>
          {pages.slice(0, PAGINATION_SIZE).map((p) => (
            <PageButton
              key={p}
              p={p}
              currentPage={currentPage}
              setPage={setPage}
              zeroIndexed={zeroIndexed}
              isLoading={isLoading}
            />
          ))}
          <button className={`transparent-btn ${loadingStyle}`}>...</button>
          <PageButton
            p={last - 1}
            currentPage={currentPage}
            setPage={setPage}
            zeroIndexed={zeroIndexed}
            isLoading={isLoading}
          />
        </>
      );
    } else if (currentPage > last - (PAGINATION_SIZE - 1)) {
      return (
        <>
          <PageButton
            p={zeroIndexed ? 0 : 1}
            currentPage={currentPage}
            setPage={setPage}
            zeroIndexed={zeroIndexed}
            isLoading={isLoading}
          />
          <button className={`transparent-btn ${loadingStyle}`}>...</button>
          {pages.slice(last - PAGINATION_SIZE, last).map((p) => (
            <PageButton
              key={p}
              p={p}
              currentPage={currentPage}
              setPage={setPage}
              zeroIndexed={zeroIndexed}
              isLoading={isLoading}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          <PageButton
            p={zeroIndexed ? 0 : 1}
            currentPage={currentPage}
            setPage={setPage}
            zeroIndexed={zeroIndexed}
            isLoading={isLoading}
          />
          <button className={`transparent-btn ${loadingStyle}`}>...</button>
          {pages
            .slice(
              currentPage - PAGINATION_SIZE / 2,
              currentPage + PAGINATION_SIZE / 2 - 1
            )
            .map((p) => (
              <PageButton
                key={p}
                p={p}
                currentPage={currentPage}
                setPage={setPage}
                zeroIndexed={zeroIndexed}
                isLoading={isLoading}
              />
            ))}
          <button className={`transparent-btn ${loadingStyle}`}>...</button>
          <PageButton
            p={last - 1}
            currentPage={currentPage}
            setPage={setPage}
            zeroIndexed={zeroIndexed}
            isLoading={isLoading}
          />
        </>
      );
    }
  } else {
    return (
      <>
        {pages.map((p) => (
          <PageButton
            key={p}
            p={p}
            currentPage={currentPage}
            setPage={setPage}
            zeroIndexed={zeroIndexed}
            isLoading={isLoading}
          />
        ))}
      </>
    );
  }
};

const FirstPageButton = ({
  page,
  setPage,
  zeroIndexed,
  isLoading,
}: {
  page: number;
  setPage: (page: number) => void;
  zeroIndexed: boolean;
  isLoading: boolean;
}) => (
  <button
    className={`transparent-btn ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}
    onClick={() => setPage(zeroIndexed ? 0 : 1)}
    disabled={page === (zeroIndexed ? 0 : 1)}
  >
    <ChevronFirstIcon />
  </button>
);

const LastPageButton = ({
  page,
  setPage,
  last,
  zeroIndexed,
  isLoading,
}: {
  page: number;
  setPage: (page: number) => void;
  last: number;
  zeroIndexed: boolean;
  isLoading: boolean;
}) => (
  <button
    className={`transparent-btn ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}
    onClick={() => setPage(last - (zeroIndexed ? 1 : 0))}
    disabled={page >= last - (zeroIndexed ? 1 : 0)}
  >
    <ChevronLast />
  </button>
);

const PrevPageButton = ({
  page,
  setPage,
  zeroIndexed,
  isLoading,
}: {
  page: number;
  setPage: (page: number) => void;
  zeroIndexed: boolean;
  isLoading: boolean;
}) => (
  <button
    className={`mr-4 transparent-btn ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}
    onClick={() => setPage(Math.max(zeroIndexed ? 0 : 1, page - 1))}
    disabled={page === (zeroIndexed ? 0 : 1)}
  >
    <ChevronLeftIcon className="mr-1" />
  </button>
);

const NextPageButton = ({
  page,
  setPage,
  last,
  zeroIndexed,
  isLoading,
}: {
  page: number;
  setPage: (page: number) => void;
  last: number;
  zeroIndexed: boolean;
  isLoading: boolean;
}) => (
  <button
    className={`ml-4 transparent-btn ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}
    onClick={() => setPage(Math.min(last - (zeroIndexed ? 1 : 0), page + 1))}
    disabled={page >= last - (zeroIndexed ? 1 : 0)}
  >
    <ChevronRightIcon />
  </button>
);

const PageButton = ({
  p,
  currentPage,
  setPage,
  zeroIndexed,
  isLoading,
}: {
  p: number;
  currentPage: number;
  setPage: (page: number) => void;
  zeroIndexed: boolean;
  isLoading: boolean;
}) => (
  <button
    className={`transparent-btn ${p === currentPage ? 'bg-sky-700 text-white' : ''} ${isLoading ? 'opacity-50 pointer-events-none' : ''}`}
    onClick={() => setPage(p)}
    disabled={isLoading}
  >
    {p + (zeroIndexed ? 1 : 0)}
  </button>
);

export default Paginator;
