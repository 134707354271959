import PageLoader from '@/core/components/page-loader';
import Grant from '@/shared/models/grant';
import GrantItem from './components/grant-item';

interface Props {
  grants: Grant[];
}
// grant list
const Index = ({ grants }: Props) => {
  return (
    <div className="flex flex-col gap-4 max-w-full">
      {grants.length === 0 ? (
        <PageLoader />
      ) : (
        grants.map((grant) => <GrantItem key={grant.id} grant={grant} />)
      )}
    </div>
  );
};

export default Index;
