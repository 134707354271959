import { useAuth0 } from '@auth0/auth0-react';
import CodeSnippet from '@/core/components/code-snippet';

import { isStringNullOrEmpty } from '@/core/lib/utils';

const Index = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <div className="content-layout">
      <h1 id="page-title" className="content__title mb-2">
        Page de profil
      </h1>
      <div className="content__body">
        <div className="profile-grid flex flex-col gap-4">
          <div className="profile__header flex items-center gap-4">
            <img
              src={
                isStringNullOrEmpty(user.picture)
                  ? '/icons/avatar.svg'
                  : user.picture
              }
              alt="Profil"
              className="profile__avatar rounded-full h-8 w-8 min-h-8 min-w-8"
              referrerPolicy="no-referrer"
            />
            <div className="profile__headline flex flex-col">
              <h2 className="profile__title">{user.name}</h2>
              <span className="profile__description">{user.email}</span>
            </div>
          </div>
          <div className="profile__details">
            <CodeSnippet
              title="Jeton d'identification (ID Token) décodé"
              code={JSON.stringify(user, null, 2)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
