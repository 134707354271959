import { useAuth0 } from '@auth0/auth0-react';

const Index = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <h1 className="text-4xl font-bold">404</h1>
      <p className="text-lg">Page not found</p>
    </div>
  );
};

export default Index;
