import { useState, Fragment } from 'react';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';

import {
  DeleteIcon,
  EllipsisVertical,
  FileIcon,
  UploadIcon,
} from 'lucide-react';
import { useFiles } from '@/core/hooks/use-files';

interface FileResult {
  target: {
    name: string;
    value: string;
  };
}

interface FileInputProps {
  defaultValue?: string;
  onChange: (e: FileResult) => void;
  name: string;
  folder: string;
  className?: string;
}

const FileInput = ({
  defaultValue,
  onChange,
  name,
  folder,
  className,
}: FileInputProps) => {
  const { uploadFile } = useFiles();

  const [value, setValue] = useState(defaultValue);

  const [loading, setLoading] = useState(false);
  let preview: JSX.Element | null = null;

  if (!value) {
    preview = null;
  } else if (value.indexOf('.pdf') !== -1) {
    preview = (
      <div
        className={`${className} absolute top-0 left-0 h-full w-full bg-gray-300 opacity-80 hover:opacity-90 flex items-center justify-center cursor-pointer text-[12px] text-black font-normal`}
      >
        <FileIcon size={48} />
      </div>
    );
  } else {
    preview = (
      <img
        className={`object-cover h-full w-full`}
        src={value}
        alt="user.png"
      />
    );
  }
  const handleDeleteFile = async () => {
    onChange({ target: { value: '', name } });
  };

  if (!value)
    return (
      <div
        className={`${className} relative w-20 h-20 rounded-xl border border-gray-200`}
      >
        <label
          htmlFor={`openFile-${name}`}
          className="absolute top-0 right-0 px-3 py-2 w-full h-full hover:backdrop-blur-sm cursor-pointer flex flex-col items-center justify-center"
        >
          <UploadIcon />
          <span className="text-sm">Upload</span>
        </label>
        <input
          id={`openFile-${name}`}
          type="file"
          className="hidden"
          disabled={loading}
          onChange={async (e) => {
            if (loading) return;
            if (!e?.target?.files || e.target.files.length === 0) return;
            setLoading(true);
            const f = e.target.files[0];
            const data = await uploadFile(f, folder);
            setValue(data!);
            onChange({ target: { value: data!, name } });
            setLoading(false);
          }}
        />
      </div>
    );

  return (
    <div className={`${className} relative w-20 h-20`}>
      <div className="rounded-xl h-full w-full overflow-hidden border border-gray-200">
        {preview}
      </div>
      <div className="absolute top-0 right-0">
        <Popover className="relative">
          <PopoverButton className="p-1 group inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
            <div className="p-1 flex items-center justify-center rounded-full bg-gray-100 opacity-60 group-hover:opacity-100">
              <EllipsisVertical className="text-lg text-black" />
            </div>
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel className="absolute left-[165%] z-10 flex w-screen max-w-min -translate-x-1/2 px-4">
              <div className="flex flex-col shrink rounded-xl overflow-hidden bg-white text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 divide-y divider-gray-200">
                {/* Edit button.
                 * Not supported as PUT /files route isn't implemented yet.
                 */}
                {/* <label
                  htmlFor={`openFile-${name}`}
                  className="px-3 py-2 w-full hover:bg-gray-50 cursor-pointer"
                >
                  <div className="flex gap-1 items-center">
                    <EditIcon />
                    Edit
                  </div>
                </label> */}
                <button
                  className="px-3 py-2 w-full hover:bg-gray-50 text-left flex gap-4 items-center"
                  onClick={handleDeleteFile}
                >
                  <DeleteIcon size={16} />
                  Supprimer
                </button>
              </div>
            </PopoverPanel>
          </Transition>
        </Popover>
      </div>
      <input
        id={`openFile-${name}`}
        type="file"
        className="hidden"
        onChange={async (e) => {
          if (loading) return;
          if (!e?.target?.files || e.target.files.length === 0) return;
          setLoading(true);
          const f = e.target.files[0];
          const data = await uploadFile(f, folder);
          setValue(data!);
          onChange({ target: { value: data!, name } });
          setLoading(false);
        }}
      />
    </div>
  );
};

export default FileInput;
