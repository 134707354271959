import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';
import { withRoleBasedRedirect } from './with-role-based-redirect';
import PageLoader from '@/core/components/page-loader';
import { Role } from '@/core/types/role';

interface AuthenticationGuardProps {
  component: ComponentType<object>;
  roles?: Role | Role[];
}

export const AuthenticationGuard = ({
  component,
  roles,
}: AuthenticationGuardProps) => {
  const getRequiredRoles = () => {
    if (!roles) return [];
    return Array.isArray(roles) ? roles : [roles];
  };
  const Component = withAuthenticationRequired(
    withRoleBasedRedirect(component, {
      roles: getRequiredRoles(),
    }),
    {
      onRedirecting: PageLoader,
    }
  );

  return <Component />;
};
