import GrantType from '@/shared/models/grantType';
import PaginateParams from '@/core/types/paginateParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';

export const useGrantTypes = () => {
  const getApi = useApi(); // This is the async function that returns an Api instance

  const searchGrantTypes = async (paginate?: PaginateParams) => {
    const api = await getApi(); // Await the API instance before using it
    const url =
      '/grant-types/search' + (paginate ? '?' + paginate.toUrlParams() : '');
    const data = await api.get<RepoResult<Pagination<GrantType>>>(url);
    return data;
  };

  const getGrantType = async (id: string) => {
    const api = await getApi(); // Await the API instance before using it
    return await api.get<RepoResult<GrantType>>('/grant-types/' + id);
  };

  const deleteGrantType = async (id: string) => {
    const api = await getApi(); // Await the API instance before using it
    return await api.delete<RepoResult<number>>('/grant-types/' + id);
  };

  const deleteGrantTypes = async (ids: string) => {
    const api = await getApi(); // Await the API instance before using it
    return await api.delete<RepoResult<number>>('/grant-types/delete/' + ids);
  };

  return { searchGrantTypes, getGrantType, deleteGrantType, deleteGrantTypes };
};
