import * as Yup from 'yup';

export const createFarmerSchema = Yup.object().shape({
  advisorOrganizationId: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  isAdmin: Yup.boolean().default(false),
  givenName: Yup.string().min(
    2,
    'Le prénom doit contenir au moins 2 caractères'
  ),
  familyName: Yup.string().min(2, 'Le nom doit contenir au moins 2 caractères'),
  email: Yup.string()
    .required("L'email est requis")
    .email("L'email est invalide"),
});
export type CreateFarmerFormType = Yup.InferType<typeof createFarmerSchema>;

export interface CreateFarmer {
  email: string;
  familyName: string;
  givenName: string;
  advisorOrganizationId?: string;
  isAdmin?: boolean;
}
