export interface IConfiguration {
  version: string;
  isServed: boolean;
  env: 'Development' | 'Test' | 'Production';
  production: boolean;
  domain: string;
  clientId: string;
  redirectUri: string;
  authorizationParams: {
    audience: string;
    scope: string;
  };
  apiUri: string;
  appUri: string;
}

class ConfigurationService {
  configuration: IConfiguration;

  constructor() {
    this.configuration = {
      version: '1.0.0',
      isServed: false,
      env: 'Development',
      production: false,
      domain: 'boostaferme.eu.auth0.com',
      clientId: 'GCPcZbY44W3rCVpaMEAj9bPH0bQRrV11',
      redirectUri: 'http://localhost:4000',
      authorizationParams: {
        audience: 'https://quickstarts/api',
        scope: 'openid profile email offline_access',
      },
      apiUri: 'https://localhost:8080',
      appUri: 'http://localhost:4000',
    };
  }

  setConfiguration(configuration: IConfiguration) {
    this.configuration = configuration;
    if (import.meta.env.VITE_OVERRIDE_DOMAIN_URL) {
      this.configuration.domain = import.meta.env.VITE_OVERRIDE_DOMAIN_URL;
    }
  }

  getConfiguration(): IConfiguration {
    return this.configuration;
  }
}

const configurationService = new ConfigurationService();
export default configurationService;
