import { useState } from 'react';
import { sanitize } from '../lib/utils';

interface TruncatedTextProps {
  str?: string;
  html?: string;
  n?: number; // Number of lines
  description?: string;
  displayOnHover?: boolean;
}

const TruncatedText = ({
  str,
  html,
  n = 2,
  displayOnHover = false,
  description,
}: TruncatedTextProps) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!str && !html) return '-';

  return (
    <div className="group flex relative">
      <span
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="line-clamp"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: n,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
        dangerouslySetInnerHTML={sanitize(html)}
      >
        {str}
      </span>
      <div
        className={`z-10 w-full whitespace-normal bg-gray-800 text-sm rounded-md absolute left-1/2 -translate-x-1/2 top-full m-4 mx-auto ${
          isHovered && displayOnHover ? '' : 'hidden'
        }`}
      >
        <div className="px-[8px] py-[4px] text-gray-100">{str}</div>
        {description ? (
          <div className="px-[8px] py-[4px] text-xs text-gray-400 border-t-2 border-gray-600">
            {description}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TruncatedText;
