import Farmer from '@/shared/models/farmer';
import { getUserName } from '@/shared/models/user';
import { useCurrentPreferredRole } from '@/stores/slices/user';
import { NavLink } from 'react-router-dom';

interface Props {
  farmer: Farmer;
}
// farmer list
const ListItem = ({ farmer }: Props) => {
  const user = farmer.User!;
  const role = useCurrentPreferredRole();

  return (
    <NavLink
      to={`/${role}/farmers/${farmer.id}`}
      className="flex flex-col bg-white shadow-md rounded-lg relative items-center p-6 cursor-pointer h-48"
    >
      {/* Farmer info */}
      <div className="flex flex-col items-center gap-2">
        <img
          src={user.picture || '/icons/avatar.svg'}
          alt={getUserName(user, true)}
          className="w-20 h-20 rounded-full object-cover"
        />
        <div className="flex flex-col items-center gap-1">
          <p className="text-md font-semibold">{getUserName(user)}</p>
          <p className="text-gray-500 text-sm">{user.email}</p>
        </div>
      </div>

      {/* Actions */}
      {/* TODO: Add "invite" and "delete" buttons */}
      {/* <button
        className="mt-4 p-2 cursor-pointer"
        onClick={() => navigate(`/${role}/farmers/${farmer.id}`)}
      >
        <div className="p-2 rounded-full bg-accent text-primary-foreground">
          <EyeIcon size={16} />
        </div>
      </button> */}
    </NavLink>
  );
};

export default ListItem;
