import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import FileInput from '@/core/components/controls/files/file-input';
import User from '@/shared/models/user';
import { FarmerFormType } from '@/shared/schemas/farmer-schema';

const UserFields = ({
  values,
  register,
  setValue,
  errors,
  className,
}: {
  values: User;
  register: UseFormRegister<FarmerFormType>;
  setValue: UseFormSetValue<FarmerFormType>;
  errors: FieldErrors<FarmerFormType>;
  className?: string;
}) => {
  return (
    <div className={`${className} border rounded-lg shadow-md p-6 bg-white`}>
      <div className="flex flex-col gap-4 items-start">
        <input type="hidden" {...register('user.id', { value: values.id })} />

        {/* User header */}
        <div className="flex items-start gap-4 w-full">
          <div className="flex flex-col">
            <input
              type="hidden"
              {...register('user.picture', { value: values.picture })}
            />
            <FileInput
              className="!h-[88px] !w-[88px] rounded-full"
              name="farmer-picture"
              defaultValue={values.picture}
              folder={`/farmer`}
              onChange={(e) => {
                setValue('user.picture', e.target.value);
              }}
            />
          </div>

          <div className="flex flex-col gap-2 flex-1">
            <div className="flex gap-2">
              <div className="flex flex-col gap-2 w-1/2">
                <input
                  id="givenName"
                  {...register('user.givenName')}
                  placeholder="Prénom"
                  type="text"
                  defaultValue={values.givenName}
                  className="border border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                />
                {errors?.user?.givenName && (
                  <span className="text-red-500 text-sm mt-[-0.5rem]">
                    {errors?.user?.givenName.message}
                  </span>
                )}
              </div>

              <div className="flex flex-col gap-2 w-1/2">
                <input
                  {...register('user.familyName')}
                  placeholder="Nom"
                  type="text"
                  defaultValue={values?.familyName ?? ''}
                  className="border border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
                />
                {errors?.user?.familyName && (
                  <span className="text-red-500 text-sm mt-[-0.5rem]">
                    {errors?.user?.familyName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-2 w-full">
              <input
                {...register('user.email')}
                placeholder="Email"
                type="email"
                defaultValue={values.email}
                className="border border-gray-300 placeholder:text-gray-300 rounded-lg text-gray-800 px-3 py-2"
              />
              {errors?.user?.email && (
                <span className="text-red-500 text-sm mt-[-0.5rem]">
                  {errors?.user?.email.message}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFields;
