import { CSSProperties } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

const PageLoader = () => {
  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
  };

  return (
    <FadeLoader color="#var(--primary)" loading={true} cssOverride={override} />
  );
};
export default PageLoader;
