import originalFetch from 'isomorphic-fetch';
import fetchRetry from 'fetch-retry';

const fetch = fetchRetry(originalFetch, {
  retries: 10,
  retryDelay: 800,
});

class ApiService {
  token?: string;
  apiURL: string;
  constructor(token: string, apiURL: string) {
    this.token = token;
    this.apiURL = apiURL;
  }

  getToken() {
    return this.token;
  }

  setToken(token?: string) {
    this.token = token;
  }

  getApiURL() {
    return this.apiURL;
  }

  setApiURL(apiURL: string) {
    this.apiURL = apiURL;
  }

  async get<T>(path: string): Promise<T> {
    try {
      const response = await fetch(`${this.apiURL}${path}`, {
        mode: 'cors',
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.token}`,
        },
      });
      if (response.status > 299 || response.status < 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async put<B, T>(path: string, body: B | string): Promise<T> {
    try {
      const response = await fetch(`${this.apiURL}${path}`, {
        mode: 'cors',
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.token}`,
        },
        body: typeof body === 'string' ? body : JSON.stringify(body),
      });
      if (response.status > 299 || response.status < 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async patch<B, T>(path: string, body: Partial<B> | string): Promise<T> {
    try {
      const response = await fetch(`${this.apiURL}${path}`, {
        mode: 'cors',
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.token}`,
        },
        body: typeof body === 'string' ? body : JSON.stringify(body),
      });
      if (response.status > 299 || response.status < 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async remove<T>(path: string): Promise<T> {
    try {
      const response = await fetch(`${this.apiURL}${path}`, {
        mode: 'cors',
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.token}`,
        },
      });
      if (response.status > 299 || response.status < 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async post<B, T>(path: string, body: B | string): Promise<T> {
    try {
      const response = await fetch(`${this.apiURL}${path}`, {
        mode: 'cors',
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.token}`,
        },
        body: typeof body === 'string' ? body : JSON.stringify(body),
      });
      if (response.status > 299 || response.status < 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete<T>(path: string): Promise<T> {
    try {
      const response = await fetch(`${this.apiURL}${path}`, {
        mode: 'cors',
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${this.token}`,
        },
      });
      if (response.status > 299 || response.status < 200) {
        console.error(response);
        throw new Error(response.statusText);
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

const apiService = new ApiService('', '');
export default apiService;
