import Tag from '@/core/../shared/models/tag';
import PaginateParams from '@/core/types/paginateParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';

export const useTags = () => {
  const getApi = useApi(); // This is the async function that returns an Api instance

  const searchTags = async (paginate?: PaginateParams) => {
    const api = await getApi(); // Await the API instance before using it
    const url = '/tags/search' + (paginate ? '?' + paginate.toUrlParams() : '');
    const data = await api.get<RepoResult<Pagination<Tag>>>(url);
    return data;
  };

  const getTag = async (id: string) => {
    const api = await getApi(); // Await the API instance before using it
    return await api.get<RepoResult<Tag>>('/tags/' + id);
  };

  const deleteTag = async (id: string) => {
    const api = await getApi(); // Await the API instance before using it
    return await api.delete<RepoResult<number>>('/tags/' + id);
  };

  const deleteTags = async (ids: string) => {
    const api = await getApi(); // Await the API instance before using it
    return await api.delete<RepoResult<number>>('/tags/delete/' + ids);
  };

  return { searchTags, getTag, deleteTag, deleteTags };
};
