import Admin from '@/shared/models/admin';
import { useState } from 'react';
import toast from 'react-hot-toast';
import IconButton from '@/core/components/controls/buttons/icon-button';
import { PlusIcon } from 'lucide-react';
import ConfirmModal from '@/core/components/confirm-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateFarmer,
  CreateFarmerFormType,
  createFarmerSchema,
} from '@/shared/schemas/farmer-create-schema';
import { FieldErrors, useForm, UseFormRegister } from 'react-hook-form';
import { useFarmers } from '@/core/hooks/use-farmers';
import { useCurrentAdvisorOrganization } from '@/stores/slices/user';
import { Skeleton } from '@/core/components/skeleton';

interface CreateProps {
  onCreated: (admin: Admin) => void;
}

const Create = ({ onCreated }: CreateProps) => {
  const organization = useCurrentAdvisorOrganization();
  const { createFarmer } = useFarmers();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onSave = async (user: CreateFarmer) => {
    try {
      setLoading(true);
      const res = await createFarmer(user);
      if (!res.value) throw new Error('Erreur lors de la création');
      if (res.isFailure) throw res.error;
      toast.success('Agriculteur ajouté');
      setOpen(false);
      onCreated(res.value);
    } catch (e) {
      console.error(e);
      toast.error('Erreur lors de la création', e.message);
    } finally {
      setLoading(false);
    }
  };

  const formOptions = { resolver: yupResolver(createFarmerSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const submitForm = handleSubmit((data) => {
    onSave(data as CreateFarmer);
  });

  if (!organization?.id) return <Skeleton className="h-8 w-52" />;

  return (
    <div>
      <IconButton
        onClick={() => setOpen(true)}
        icon={<PlusIcon size={16} />}
        padding="px-3 py-2"
        endLabel="Ajouter un agriculteur"
      />

      <ConfirmModal
        isOpen={open}
        onCancel={() => setOpen(false)}
        onConfirm={submitForm}
        closeOnConfirm={false}
        message="Créer un agriculteur"
        confirmLabel="Créer"
        loading={loading}
      >
        <Fields
          advisorOrganizationId={organization.id}
          register={register}
          errors={errors}
        />
      </ConfirmModal>
    </div>
  );
};

const Fields = ({
  advisorOrganizationId,
  register,
  errors,
}: {
  advisorOrganizationId: string;
  register: UseFormRegister<CreateFarmerFormType>;
  errors: FieldErrors<CreateFarmerFormType>;
}) => {
  return (
    <div className="flex-1 flex flex-col mt-4 gap-1 sm:gap-2 overflow-y-auto">
      <input
        type="hidden"
        {...register('advisorOrganizationId', { value: advisorOrganizationId })}
      />
      <input type="hidden" {...register('isAdmin', { value: false })} />

      <div className="flex gap-2 items-center justify-start">
        <label htmlFor="email" className="min-w-[80px]">
          Email
        </label>
        <div className="flex flex-col flex-1">
          <input
            id="email"
            {...register('email')}
            placeholder="Email de l'agriculteur"
            className="bg-gray-100 p-2 rounded-md w-full max-w-full"
          />
          {errors?.email && (
            <span className="text-red-500 text-sm">
              {errors?.email.message}
            </span>
          )}
        </div>
      </div>

      <div className="flex gap-2 items-center justify-start">
        <label htmlFor="givenName" className="min-w-[80px]">
          Prénom
        </label>
        <div className="flex flex-col flex-1">
          <input
            id="givenName"
            {...register('givenName')}
            placeholder="Prénom de l'agriculteur"
            className="bg-gray-100 p-2 rounded-md w-full max-w-full"
          />
          {errors?.givenName && (
            <span className="text-red-500 text-sm">
              {errors?.givenName.message}
            </span>
          )}
        </div>
      </div>

      <div className="flex gap-2 items-center justify-start">
        <label htmlFor="familyName" className="min-w-[80px]">
          Nom
        </label>
        <div className="flex flex-col flex-1">
          <input
            id="familyName"
            {...register('familyName')}
            placeholder="Nom de l'agriculteur"
            className="bg-gray-100 p-2 rounded-md w-full max-w-full"
          />
          {errors?.familyName && (
            <span className="text-red-500 text-sm">
              {errors?.familyName.message}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
