import PaginateParams from './paginateParams';

export default class FarmersParams extends PaginateParams {
  constructor(
    public organizationId?: string,
    params?: PaginateParams
  ) {
    super(
      params?.search,
      params?.sortBy,
      params?.order,
      params?.pageSize,
      params?.pageNumber,
      params?.columnFilters,
      params?.columnOrders,
      params?.include
    );
    this.organizationId = organizationId;
  }

  override toUrlParams(): URLSearchParams {
    const params = super.toUrlParams();
    if (this.organizationId)
      params.append('organizationId', this.organizationId);
    return params;
  }

  override toUrl(): string {
    const url = this.toUrlParams().toString();
    return url;
  }

  static fromUrl(urlParams: URLSearchParams): FarmersParams {
    const organizationId = urlParams?.get('organizationId') ?? undefined;
    return new FarmersParams(organizationId, PaginateParams.fromUrl(urlParams));
  }
}
