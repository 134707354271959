import { IRouteObject } from '@/core/types/IRouteObject';
import { flattenedRoutes, routes } from '@/routes.config';
import { matchPath } from 'react-router-dom';
import { create, type StateCreator } from 'zustand';

export interface Path {
  label?: string;
  url: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}
export type NavigationStore = {
  readonly paths: Path[];
};

const initialState: NavigationStore = {
  paths: [],
};

export type NavigationAction = {
  setPaths: (paths: Path[]) => void;
  setCurrentPath(path: Path): void;
};

// Recursive function to search through routes and their children
const findRoute = (
  routes: IRouteObject[],
  path: string,
  matchingRoutes: IRouteObject[]
): void => {
  for (const route of routes) {
    if (matchRoute(path, route)) {
      matchingRoutes.push(route);
      if (route.children) {
        findRoute(route.children, path, matchingRoutes);
      }
    }
  }
};

const getMatchingRoutes = (path: string): IRouteObject[] => {
  // Start the search with the top-level routes
  const matchingRoutes: IRouteObject[] = [];
  findRoute(routes, path, matchingRoutes);
  const sortedRoutes = matchingRoutes.sort((a, b) => {
    return a.absolutePath.split('/').length - b.absolutePath.split('/').length;
  });
  return sortedRoutes;
};

const findDeepestRoute = (path: string): IRouteObject | undefined => {
  for (const route of flattenedRoutes) {
    if (matchPath({ path: route.absolutePath, caseSensitive: false }, path)) {
      return route;
    }
  }
  return undefined;
};

export const getPathAtRouteLevel = (
  path: string,
  route: string
): string | undefined => {
  const pathLength = path.split('/').length;
  const routeLength = route.split('/').length;
  if (pathLength < routeLength) {
    return undefined;
  }
  return path.split('/').slice(0, routeLength).join('/');
};

function matchRoute(
  path: string,
  route: IRouteObject
): IRouteObject | undefined {
  if (route.absolutePath) {
    const pathAtRouteLevel = getPathAtRouteLevel(path, route.absolutePath);
    if (!pathAtRouteLevel) {
      return null;
    }

    if (
      matchPath(
        { path: route.absolutePath, caseSensitive: false },
        pathAtRouteLevel
      )
    ) {
      return route;
    }
  }

  return null;
}

const populateRoute = (path: Path, route?: IRouteObject) => {
  const currentRoute = route ?? findDeepestRoute(path.url);

  path.label ??= currentRoute.label;
  path.startIcon ??= currentRoute.startIcon;
  path.endIcon ??= currentRoute.endIcon;

  if (currentRoute.dynamic) {
    currentRoute.label = path.label;
  }
};

export const navigationStoreCreator: StateCreator<
  NavigationStore & NavigationAction
> = (set) => ({
  ...initialState,
  setPaths: (paths: Path[]) => set(() => ({ paths })),
  setCurrentPath: (path: Path) =>
    set(() => {
      populateRoute(path);
      const routes = getMatchingRoutes(path.url);
      const labelledRoutes = routes.filter((r) => r.label);
      const paths = labelledRoutes.map((r) => ({
        label: r.label,
        url: getPathAtRouteLevel(path.url, r.absolutePath) ?? '',
        startIcon: r.startIcon,
        endIcon: r.endIcon,
      }));
      return { paths };
    }),
});
const useNavigation = create(navigationStoreCreator);

// Custom hooks
export const usePaths = () => useNavigation((state) => state.paths);
export const useNavigationActions = () => useNavigation((state) => state);
