import { useEffect, useState } from 'react';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import 'react-tagsinput/react-tagsinput.css';
import { CheckIcon, ChevronDown } from 'lucide-react';

export interface LabelValue {
  label: string;
  value: string;
}

interface MultipleAutocompleteSelectProps {
  options: LabelValue[];
  selected?: LabelValue[];
  label?: string;
  placeholder?: string;
  className?: string;
  onChange: (selectedItems: LabelValue[]) => void;
  disabled?: boolean;
}

const MultipleAutocompleteSelect = ({
  options,
  selected,
  label = '',
  placeholder = '',
  className = '',
  onChange,
  disabled,
}: MultipleAutocompleteSelectProps) => {
  const [selectedItems, setSelectedItems] = useState<LabelValue[]>(
    selected || []
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setSelectedItems(selected || []);
  }, [selected]);

  useEffect(() => {
    if (query === '') {
      setFilteredOptions(options);
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  }, [query, options]);

  // Add a new item to the selected list
  const handleSelect = (item?: LabelValue) => {
    const isAlreadySelected = selectedItems.find(
      (selected) => selected.value === item?.value
    );
    if (!item) {
      setQuery('');
      return;
    }
    if (!isAlreadySelected) {
      const newSelectedItems = [...selectedItems, item];
      setSelectedItems(newSelectedItems);
      onChange(newSelectedItems); // Pass selected items to parent
    } else {
      // If the item is already selected, remove it
      handleRemoveTag(item);
    }
    setQuery('');
  };

  // Remove a selected tag
  const handleRemoveTag = (tagToRemove: LabelValue) => {
    const newSelectedItems = selectedItems.filter(
      (item) => item.value !== tagToRemove.value
    );
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems); // Pass updated items to parent
  };

  return (
    <div>
      {label && (
        <div className="text-sm font-medium text-gray-600">{label}</div>
      )}
      <div className="relative mt-2">
        <Combobox
          onChange={(value) => handleSelect(value as unknown as LabelValue)}
        >
          {({ open }) => {
            return (
              <>
                <div className="relative">
                  <ComboboxInput
                    className="w-full rounded-lg border-none bg-white shadow-sm py-1.5 pr-8 pl-3 text-sm/6 text-black focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    displayValue={(value: LabelValue) => value?.label}
                    placeholder={placeholder}
                    value={query}
                    onChange={(e) => {
                      setQuery(e.currentTarget.value);
                    }}
                    disabled={disabled}
                  />
                  <ComboboxButton
                    className="group absolute inset-y-0 right-0 px-2.5"
                    disabled={disabled}
                  >
                    <ChevronDown className="size-4 group-data-[hover]:text-accent" />
                  </ComboboxButton>
                </div>
                <AnimatePresence>
                  {open && (
                    <ComboboxOptions
                      static
                      as={motion.div}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      anchor="bottom"
                      className={`${className} origin-top mt-2 w-[var(--input-width)] rounded-xl border bg-white border-white/5 shadow-md p-1 [--anchor-gap:var(--spacing-1)] empty:invisible`}
                    >
                      {filteredOptions.length === 0 && query !== '' && (
                        <div className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10">
                          Pas d'options
                        </div>
                      )}
                      {filteredOptions.map((option) => (
                        <ComboboxOption
                          key={option.value}
                          value={option}
                          className="group flex cursor-pointer items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10 text-black hover:text-accent"
                          onSelect={() => {
                            handleSelect(option);
                            setQuery(''); // Reset input text after selecting an option
                          }}
                        >
                          {selectedItems.includes(option) ? (
                            <>
                              <CheckIcon className="size-4 text-accent" />
                              <div className="text-sm/6 text-accent">
                                {option.label}
                              </div>
                            </>
                          ) : (
                            <div className="text-sm/6 text-inherit">
                              {option.label}
                            </div>
                          )}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  )}
                </AnimatePresence>
              </>
            );
          }}
        </Combobox>
      </div>
      <div className="flex flex-wrap mt-2 gap-[4px]">
        {selectedItems.map((tag) => (
          <Tag key={tag.value} tag={tag} onRemove={handleRemoveTag} />
        ))}
      </div>
    </div>
  );
};

const Tag = ({
  tag,
  onRemove,
}: {
  tag: LabelValue;
  onRemove: (tag: LabelValue) => void;
}) => (
  <span
    key={tag.value}
    className="px-3 py-2 flex items-center justify-between rounded-xl bg-gray-200 text-gray-800 gap-2 cursor-pointer group"
    style={{ fontSize: '12px' }}
    onClick={() => onRemove(tag)}
  >
    {tag.label}
    <button
      type="button"
      className="text-sm text-gray-600 group-hover:text-red-400"
    >
      &times;
    </button>
  </span>
);

export default MultipleAutocompleteSelect;
