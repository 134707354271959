import { ChevronUpIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './scroll-to-top.module.scss';
import { useScrollPosition } from '@/core/hooks/use-scroll-position';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [showTopBtn, setShowTopBtn] = useState(false);

  useScrollPosition(({ currPos }) => {
    setShowTopBtn(Math.abs(currPos.y) > 400);
  });

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className={styles.topToBtm}>
      {' '}
      <div
        className={`${styles.iconPosition} ${styles.iconStyle}
          ${showTopBtn ? 'opacity-70' : 'opacity-0'}
          `}
      >
        <ChevronUpIcon onClick={goToTop} />
      </div>{' '}
    </div>
  );
};
export default ScrollToTop;
