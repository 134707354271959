import User from '@/core/../shared/models/user';
import PaginateParams from '@/core/types/paginateParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';
import { Invitation } from '../types/invitation';

export const useUsers = () => {
  const getApi = useApi();

  const inviteUser = async (ids: string[]) => {
    const api = await getApi();
    return await api.post<{ ids: string[] }, RepoResult<User>>(
      '/users/invite',
      JSON.stringify(ids)
    );
  };

  const searchUsers = async (params?: PaginateParams) => {
    const api = await getApi();

    let url = '/users/search';
    if (params) {
      const filtersObj = new PaginateParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }

    return await api.get<RepoResult<Pagination<User>>>(url);
  };

  const getUser = async (id: string, params?: PaginateParams) => {
    const api = await getApi();

    let url = '/users/' + id;
    if (params) {
      const filtersObj = new PaginateParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }
    return await api.get<RepoResult<User>>(url);
  };

  const createUser = async (user: {
    email: string;
    advisorOrganizationId?: string;
    isAdmin?: boolean;
  }) => {
    const api = await getApi();
    return await api.post<
      { email: string; advisorOrganizationId?: string; isAdmin?: boolean },
      RepoResult<User>
    >('/users', JSON.stringify(user));
  };

  const updateUser = async (user: User) => {
    const api = await getApi();
    return await api.put<User, RepoResult<number>>(
      '/users/' + user.id,
      JSON.stringify(user)
    );
  };

  const patchUser = async (user: Partial<User>) => {
    const api = await getApi();
    const res = await api.patch<Partial<User>, RepoResult<number>>(
      '/users/' + user.id,
      JSON.stringify(user)
    );
    console.log('patchUser', res);
    return res;
  };

  const deleteUser = async (id: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/users/' + id);
  };

  const deleteUsers = async (ids: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/users/delete/' + ids);
  };

  const acceptInvitations = async (invitations: Invitation[]) => {
    const api = await getApi();
    for (const invitation of invitations) {
      const roleEndpoint = invitation.role + 's';
      await api.post<null, RepoResult<string>>(
        `/${roleEndpoint}/accept-invitation/${invitation.id}`,
        null
      );
    }
  };

  return {
    inviteUser,
    searchUsers,
    getUser,
    createUser,
    updateUser,
    patchUser,
    deleteUser,
    deleteUsers,
    acceptInvitations,
  };
};
