import { classNames } from '@/core/lib/utils';
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
} from '@headlessui/react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { NavigationItem } from '@/shared/components/page-layout';
import { getPathAtRouteLevel } from '@/stores/slices/navigation';

interface MobileDrawerProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigation: NavigationItem[];
}
export default function MobileDrawer({
  sidebarOpen,
  setSidebarOpen,
  navigation,
}: Readonly<MobileDrawerProps>) {
  const location = useLocation();
  const isActive = (href: string, end: boolean): boolean => {
    if (end) {
      return matchPath({ path: href, end: true }, location.pathname) !== null;
    }
    const locationAtRouteLevel = getPathAtRouteLevel(location.pathname, href);
    if (!locationAtRouteLevel) return false;
    return (
      matchPath({ path: href, caseSensitive: false }, locationAtRouteLevel) !==
      null
    );
  };

  return (
    <Transition show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={setSidebarOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div className="fixed inset-0 flex">
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
              {/* sidebar content */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary p-4">
                <div className="flex h-12 shrink-0 items-center mr-auto">
                  {/* Home button */}
                  <NavLink
                    end
                    to="/"
                    className="flex items-center gap-x-2"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <img
                      src="/images/boostaferme-logo-darkened-1.png"
                      alt="Boostaferme"
                      className="max-h-[3em] w-auto"
                    />
                  </NavLink>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          end={false}
                          to={item.href}
                          key={item.name}
                          className={classNames(
                            'group flex items-center gap-x-2 rounded-md py-3 px-4 text-[#6E7581] text-sm font-medium transition-colors hover:bg-accent-foreground hover:text-primary-foreground',
                            isActive(item.href, item.end)
                              ? 'bg-accent text-background hover:text-accent font-medium'
                              : ''
                          )}
                          onClick={() => setSidebarOpen(false)}
                        >
                          {item.icon}
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
