import { ApiStatus } from '../types/api-status';
import { useApi } from './use-api';

export const useApiStatus = () => {
  const getApi = useApi();

  const getApiStatus = async () => {
    const api = await getApi(true);

    return await api.get<ApiStatus>('');
  };

  return { getApiStatus };
};
