import './instrument'; // Must be the first import
import './index.scss';
import './App.css';

import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import configurationService from './core/services/configuration.service.ts';

async function initializeApp() {
  try {
    // Fetch the static config asynchronously
    const response = await fetch('/config.json');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const config = await response.json();
    configurationService.setConfiguration(config);

    // After successfully loading the config, render the React app
    const root = document.getElementById('root');
    if (!root) {
      throw new Error('No root element found');
    }
    ReactDOM.createRoot(root).render(<App />);
  } catch (error) {
    console.error('Failed to get configuration', error);
  }
}

initializeApp();
