import Grant from '@/core/../shared/models/grant';
import GrantsParams from '@/core/types/grantsParams';
import Pagination from '@/core/types/pagination';
import { RepoResult } from '@/core/types/repoResult';
import { useApi } from './use-api';

export const useGrants = () => {
  const getApi = useApi();

  const searchGrants = async (params?: GrantsParams) => {
    const api = await getApi();

    let url = '/grants/search';
    if (params) {
      const filtersObj = new GrantsParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }

    const data = await api.get<RepoResult<Pagination<Grant>>>(url);
    return data;
  };

  const getGrant = async (id: string, params?: GrantsParams) => {
    const api = await getApi();

    let url = '/grants/' + id;
    if (params) {
      const filtersObj = new GrantsParams();
      Object.assign(filtersObj, params);
      url += '?' + filtersObj.toUrl();
    }
    return await api.get<RepoResult<Grant>>(url);
  };

  const updateGrant = async (grant: Grant) => {
    const api = await getApi();
    return await api.put<Grant, RepoResult<Grant>>(
      '/grants/' + grant.id,
      grant
    );
  };

  const patchGrant = async (grant: Partial<Grant>) => {
    const api = await getApi();
    return await api.patch<Grant, RepoResult<Grant>>(
      '/grants/' + grant.id,
      grant
    );
  };

  const deleteGrant = async (id: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/grants/' + id);
  };

  const deleteGrants = async (ids: string) => {
    const api = await getApi();
    return await api.delete<RepoResult<number>>('/grants/delete/' + ids);
  };

  return {
    searchGrants,
    getGrant,
    updateGrant,
    patchGrant,
    deleteGrant,
    deleteGrants,
  };
};
