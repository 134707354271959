import { useGrants } from '@/core/hooks/use-grants';
import Grant from '@/shared/models/grant';
import { useCallback, useEffect, useRef, useState } from 'react';
import GrantList from '@/shared/components/grant-list/index.ts';
import SearchBar from '@/core/components/controls/search-bar';
import ListPlaceholder from '@/core/components/controls/placeholders/list-placeholder';
import Paginator from '@/core/components/paginator';
import GrantsParams from '@/core/types/grantsParams';
import { useGrantTypes } from '@/core/hooks/use-grant-types';
import { useLocalizations } from '@/core/hooks/use-localizations';
import { useTags } from '@/core/hooks/use-tags';
import MultipleAutocompleteSelect, {
  LabelValue,
} from '@/core/components/controls/select/multiple-autocomplete-select-input';
import { useSearchParams } from 'react-router-dom';
import IconButton from '@/core/components/controls/buttons/icon-button';
import { RotateCcw, X } from 'lucide-react';
import { isStringNullOrEmpty } from '@/core/lib/utils';
import PaginateParams from '@/core/types/paginateParams';

const getInitialFilter = (searchParams: URLSearchParams) => {
  const filter = searchParams
    ? GrantsParams.fromUrl(searchParams)
    : new GrantsParams();
  filter.include ||= 'Tag,GrantType,Localization';
  filter.pageNumber ??= 0;
  filter.pageSize ||= 10;
  return filter;
};

const searchTagsParams = new PaginateParams();
searchTagsParams.pageSize = 1000;

const GrantsList = () => {
  // Fetch data
  const { searchGrants } = useGrants();
  const { searchLocalizations } = useLocalizations();
  const { searchGrantTypes } = useGrantTypes();
  const { searchTags } = useTags();

  // Manage search params
  const [searchParams] = useSearchParams();

  const [filter, setFilter] = useState<GrantsParams>(
    getInitialFilter(searchParams)
  );
  const [prevFilter, setPrevFilter] = useState<GrantsParams>(
    getInitialFilter(searchParams)
  );
  // filter ref to access the latest filter value in the callback
  const filterRef = useRef(filter);

  const [hasFilter, setHasFilter] = useState(false);
  const [total, setTotal] = useState(0);

  // Manage state
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [grants, setGrants] = useState<Grant[]>([]);
  const [availableTags, setAvailableTags] = useState<LabelValue[]>([]);
  const [selectedTags, setSelectedTags] = useState<LabelValue[]>([]);
  const [availableLocalizations, setAvailableLocalizations] = useState<
    LabelValue[]
  >([]);
  const [selectedLocalizations, setSelectedLocalizations] = useState<
    LabelValue[]
  >([]);
  const [availableGrantTypes, setAvailableGrantTypes] = useState<LabelValue[]>(
    []
  );
  const [selectedGrantTypes, setSelectedGrantTypes] = useState<LabelValue[]>(
    []
  );

  const isDisabled = useCallback(() => {
    return isLoading || JSON.stringify(prevFilter) === JSON.stringify(filter);
  }, [isLoading, prevFilter, filter]);

  const updateSearchParams = (filter: GrantsParams) => {
    const filtersObj = new GrantsParams();
    Object.assign(filtersObj, filter);
    const url = '?' + filtersObj.toUrl();
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  const get = async () => {
    setPrevFilter(filterRef.current);
    updateSearchParams(filterRef.current);
    setIsLoading(true);
    const data = await searchGrants(filterRef.current);
    const values = data.value?.data || [];
    filterRef.current.pageSize ??= 10;
    setGrants(values);
    setTotal(data.value?.total ?? 0);
    if (!isInitialized) setIsInitialized(true);
    setIsLoading(false);
    setHasFilter(
      !isStringNullOrEmpty(filterRef.current.search) ||
        !isStringNullOrEmpty(filterRef.current.tags) ||
        !isStringNullOrEmpty(filterRef.current.grantTypes) ||
        !isStringNullOrEmpty(filterRef.current.localizations)
    );
  };

  // On enter key press, trigger search
  useEffect(() => {
    const handleKeyPress = async (e: KeyboardEvent) => {
      if (isLoading) return;
      if (e.key !== 'Enter') return;
      await get();
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  // On component mount, fetch data
  useEffect(() => {
    searchLocalizations().then((data) => {
      const values = data.value?.data || [];
      const labelValues: LabelValue[] = values.map((x) => ({
        label: x.label,
        value: x.id,
      }));
      setSelectedLocalizations(
        labelValues.filter((x) =>
          filter.localizations?.split(',').includes(x.value)
        )
      );
      setAvailableLocalizations(labelValues);
    });
    searchGrantTypes().then((data) => {
      const values = data.value?.data || [];
      const labelValues: LabelValue[] = values.map((x) => ({
        label: x.label,
        value: x.id,
      }));
      setSelectedGrantTypes(
        labelValues.filter((x) =>
          filter.grantTypes?.split(',').includes(x.value)
        )
      );
      setAvailableGrantTypes(labelValues);
    });
    searchTags(searchTagsParams).then((data) => {
      const values = data.value?.data || [];
      const labelValues: LabelValue[] = values.map((x) => ({
        label: x.label,
        value: x.id,
      }));
      setAvailableTags(labelValues);
      setSelectedTags(
        labelValues.filter((x) => filter.tags?.split(',').includes(x.value))
      );
    });
    get();
  }, []);

  return (
    <div className="max-h-full overflow-hidden">
      <h1>Annuaire des aides</h1>
      <h2 className="mb-4">
        Retrouvez toutes les aides disponibles pour les agriculteurs
      </h2>

      <div className="flex flex-col gap-2">
        <div className="flex gap-4 items-start flex-wrap">
          <MultipleAutocompleteSelect
            options={availableTags}
            selected={selectedTags}
            label="Tags"
            placeholder="Filtrer par tags"
            disabled={isLoading}
            onChange={(tags) => {
              const newFilter = new GrantsParams();
              newFilter.copy(filter);
              newFilter.tags = tags.map((x) => x.value).join(',');
              newFilter.pageNumber = 0;
              setFilter(newFilter);
            }}
            className="!max-h-60"
          />
          <MultipleAutocompleteSelect
            options={availableGrantTypes}
            selected={selectedGrantTypes}
            label="Domaines d'aides"
            placeholder="Filtrer par domaines d'aides"
            disabled={isLoading}
            onChange={(grantTypes) => {
              const newFilter = new GrantsParams();
              newFilter.copy(filter);
              newFilter.grantTypes = grantTypes.map((x) => x.value).join(',');
              newFilter.pageNumber = 0;
              setFilter(newFilter);
            }}
            className="!max-h-60"
          />
          <MultipleAutocompleteSelect
            options={availableLocalizations}
            selected={selectedLocalizations}
            label="Localisations"
            placeholder="Filtrer par localisations"
            disabled={isLoading}
            onChange={(localizations) => {
              const newFilter = new GrantsParams();
              newFilter.copy(filter);
              newFilter.localizations = localizations
                .map((x) => x.value)
                .join(',');
              newFilter.pageNumber = 0;
              setFilter(newFilter);
            }}
            className="!max-h-60"
          />
        </div>

        <div className="flex items-center gap-4 flex-wrap">
          <SearchBar
            search={filter.search}
            setFilter={setFilter}
            className="border-none focus:border-solid shadow-md"
          />

          <button
            className={`btn bg-accent text-white flex items-center gap-2
              ${isDisabled() ? 'cursor-default bg-gray-300 text-gray-500' : ''}`}
            onClick={get}
            disabled={isDisabled()}
          >
            <RotateCcw size={16} />
            Appliquer les filtres
          </button>

          <IconButton
            icon={<X size={16} />}
            onClick={async () => {
              const newFilter = getInitialFilter(new URLSearchParams());
              setFilter(newFilter);
              filterRef.current = newFilter;
              setPrevFilter(getInitialFilter(new URLSearchParams()));

              setHasFilter(false);
              setSelectedTags([]);
              setSelectedGrantTypes([]);
              setSelectedLocalizations([]);
              await get();
              setHasFilter(false);
            }}
            disabled={!hasFilter}
            fgColor="white"
            bgColor={`${isLoading ? 'gray-300' : 'accent'}`}
          />
        </div>
      </div>

      <div className="my-2" />

      <div className="flex flex-1 overflow-x-hidden overflow-y-auto justify-center items-start">
        {!isInitialized ? <ListPlaceholder /> : <GrantList grants={grants} />}
      </div>

      <Paginator
        className="my-4"
        page={filter.pageNumber ?? 0}
        setPage={(page) => {
          filter.setPage(page);
          get();
        }}
        last={Math.ceil(total / (filter.pageSize ?? 1))}
        zeroIndexed
        isLoading={isLoading}
      />
    </div>
  );
};

export default GrantsList;
