import { useFarmers } from '@/core/hooks/use-farmers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  SaveIcon,
  Eye,
  Pencil,
  ArchiveIcon,
  CopyIcon,
  DeleteIcon,
  ShareIcon,
} from 'lucide-react';
import IconButton from '@/core/components/controls/buttons/icon-button';
import { useNavigationActions } from '@/stores/slices/navigation';
import Loader from '@/core/components/loader';
import { useCurrentPreferredRole } from '@/stores/slices/user';
import ActionsMenu, {
  MenuAction,
} from '@/core/components/controls/buttons/actions-menu';
import View from './item-view';
import Edit from './item-edit';
import Farmer from '@/shared/models/farmer';
import FarmersParams from '@/core/types/farmerParams';
import { getUserName } from '@/core/lib/utils';
import { DeepPartial } from 'react-hook-form';

const params = new FarmersParams();
params.include = 'User,Farm';

const editors = ['admin', 'advisor'];

const Item = () => {
  const role = useCurrentPreferredRole();
  const allowEdit = useCallback(() => editors.includes(role), [role]);
  const { setCurrentPath } = useNavigationActions();
  const { id } = useParams();
  const { getFarmer, patchFarmer } = useFarmers();
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState<Farmer>();
  const [isLoading, setIsLoading] = useState(true);

  // Access edit form submit method via reference
  const editRef = useRef<{ submitForm: () => void }>(null);

  const setPath = (farmerLabel: string) => {
    setCurrentPath({ label: farmerLabel, url: window.location.pathname });
  };

  const get = async () => {
    if (!id) return;
    setIsLoading(true);
    const data = await getFarmer(id, params);
    console.log(data);
    setValues(data.value);
    setPath(getUserName(data.value.User));
    setIsLoading(false);
  };

  const onUpdate = async (updatedValues: DeepPartial<Farmer>) => {
    try {
      await patchFarmer(updatedValues);
      toast.success('Mise à jour réussie');
      await get();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    get();
  }, [id]);

  const getActions = useCallback(() => {
    const actions: MenuAction[] = [
      {
        label: 'Partager',
        startIcon: <ShareIcon size={16} color="#a78bfa" />,
        callback: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/farmers/${id}`
          );
          toast.success('Lien copié dans le presse-papiers');
        },
      },
    ];
    if (allowEdit()) {
      actions.push(
        {
          label: 'Dupliquer',
          startIcon: <CopyIcon size={16} color="#a78bfa" />,
          callback: () => {},
          disabled: true,
        },
        {
          label: 'Archiver',
          startIcon: <ArchiveIcon size={16} color="#a78bfa" />,
          callback: () => {},
          disabled: true,
        },
        {
          label: 'Supprimer',
          startIcon: <DeleteIcon size={16} color="#a78bfa" />,
          callback: () => {},
          disabled: true,
        }
      );
    }
    return actions;
  }, [allowEdit, id]);

  if (!id)
    return (
      <div className="text-red-500">
        L'aide agricole recherchée n'existe pas.
      </div>
    );
  if (!values || isLoading) return <Loader size="large" />;

  return (
    <div className="relative flex-1 flex flex-col gap-4">
      <div className="absolute top-[-4px] right-0 left-0 flex flex-row items-center justify-end">
        {allowEdit() && (
          <>
            <IconButton
              icon={<SaveIcon size={18} />}
              type="submit"
              disabled={!isEditing}
              fgColor="white"
              bgColor={isEditing ? 'accent' : 'gray-300'}
              bgHoverColor={isEditing ? 'accent-foreground' : 'gray-300'}
              onClick={() => editRef.current?.submitForm()} // Call the submit handler from the Edit component
            />
            <IconButton
              onClick={() => setIsEditing(!isEditing)}
              icon={isEditing ? <Eye size={18} /> : <Pencil size={18} />}
              fgColor="white"
              bgColor="accent"
              bgHoverColor="accent-foreground"
            />
          </>
        )}
        <ActionsMenu float="left" actions={getActions()} />
      </div>

      <div className="mt-12 flex flex-col w-full items-center">
        {isEditing ? (
          <Edit ref={editRef} values={values} onUpdate={onUpdate} />
        ) : (
          <View values={values} />
        )}
      </div>
    </div>
  );
};

export default Item;
