import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import Farmer from '@/shared/models/farmer';
import { yupResolver } from '@hookform/resolvers/yup';
import UserFields from './item-edit-user';
import FarmFields from './item-edit-farm';
import { farmerSchema } from '@/shared/schemas/farmer-schema';

interface EditProps {
  values: Farmer;
  onUpdate: (values: Partial<Farmer>) => void;
}

const Edit = forwardRef(({ values, onUpdate }: EditProps, ref) => {
  const formOptions = { resolver: yupResolver(farmerSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        console.error(key, errors[key].message);
      });
    }
  }, [errors]);

  // Submit form handler
  const submitForm = handleSubmit((data) => {
    onUpdate(data as Partial<Farmer>);
  });

  // Expose the submitForm function to the parent via the ref
  useImperativeHandle(ref, () => ({
    submitForm,
  }));

  return (
    <form onSubmit={submitForm} className="flex flex-col">
      <div className="flex flex-col gap-4">
        <input type="hidden" {...register('id', { value: values.id })} />

        {values?.User && (
          <UserFields
            values={values?.User}
            setValue={setValue}
            register={register}
            errors={errors}
            className="min-w-[50%]"
          />
        )}

        <div className="min-w-[50%]">
          <p className="text-lg font-semibold">Exploitations agricoles</p>
          <div className="flex flex-wrap gap-12">
            {values?.Farms?.map((farm, index) => (
              <FarmFields
                index={index}
                key={farm.id}
                values={farm}
                setValue={setValue}
                register={register}
                errors={errors}
                className="w-full"
              />
            ))}
          </div>
        </div>
      </div>
    </form>
  );
});

export default Edit;
