import { formatDate } from '@/core/lib/utils';
import User, { getUserName } from '@/shared/models/user';
import React from 'react';

interface UserViewProps {
  user: User;
  className?: string;
}

const UserView: React.FC<UserViewProps> = ({ user, className }) => {
  return (
    <div
      className={`border rounded-lg shadow-md p-6 mb-4 bg-white ${className}`}
    >
      <div className="flex items-center mb-4">
        <img
          src={user.picture || '/icons/avatar.svg'}
          alt={user.name}
          className="w-16 h-16 rounded-full mr-4 object-cover"
        />
        <div>
          <h3 className="text-sm font-semibold">{getUserName(user)}</h3>
          <p className="text-gray-500 text-sm break-all">{user.email}</p>
          <p className="text-sm">
            Abonnement:
            <span
              className={
                user.subscriptionState === 'active'
                  ? 'text-green-500'
                  : 'text-red-500'
              }
            >
              {user.subscriptionState}
            </span>
          </p>
        </div>
      </div>

      <table className="text-left text-sm w-auto">
        <tbody>
          {user.phoneNumber && (
            <tr>
              <td className="font-semibold py-1 pr-4">Téléphone:</td>
              <td>{user.phoneNumber}</td>
            </tr>
          )}
          <tr>
            <td className="font-semibold py-1 pr-4">Nom d'utilisateur:</td>
            <td>{user.username}</td>
          </tr>
          {user.blocked && (
            <tr>
              <td className="font-semibold py-1 pr-4">Bloqué:</td>
              <td>
                {user.blockedFor ? user.blockedFor : 'Aucune raison fournie'}
              </td>
            </tr>
          )}
          <tr>
            <td className="font-semibold py-1 pr-4">Email vérifié:</td>
            <td>{user.emailVerified ? '✅' : '❌'}</td>
          </tr>
          <tr>
            <td className="font-semibold py-1 pr-4">Téléphone vérifié:</td>
            <td>{user.phoneVerified ? '✅' : '❌'}</td>
          </tr>
        </tbody>
      </table>
      <div className="mt-2">
        <p className="mt-1 text-gray-500 text-xs">
          Créé le {formatDate(user.createdAt)}
        </p>
        <p className="mt-1 text-gray-500 text-xs">
          Mis à jour le {formatDate(user.updatedAt)}
        </p>
      </div>
    </div>
  );
};

export default UserView;
