import ScrollToTop from './core/components/scroll-to-top/scroll-to-top';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './core/providers/auth0-provider-with-navigate';
import Router from './routes';
import { Toaster } from 'react-hot-toast';
import PageLayout from './shared/components/page-layout';
import configurationService from './core/services/configuration.service';
import { HelmetProvider, Helmet } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Boostaferme</title>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href={configurationService.configuration.appUri}
        />
      </Helmet>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <Toaster />
          <div className="flex flex-col">
            <ScrollToTop />
            <div className="flex">
              <div className="App flex flex-col w-full">
                <PageLayout>
                  <Router />
                </PageLayout>
              </div>
            </div>
          </div>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
