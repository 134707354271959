import { useAuth0 } from '@auth0/auth0-react';
import { Role } from '@/core/types/role';

const roleClaimType = 'https://boostaferme.com/roles';

const useRoles = () => {
  const { getIdTokenClaims } = useAuth0();

  const getRoles = async () => {
    const claims = await getIdTokenClaims();
    if (!claims) return [];
    return claims[roleClaimType] as Role[];
  };

  const getUrlRole = (): Role | undefined => {
    const url = window.location.pathname;
    const urlParts = url.split('/');
    const role = urlParts[1];
    if (
      role === 'admin' ||
      role === 'advisor' ||
      role === 'farmer' ||
      role === 'maintainer'
    ) {
      return role as Role;
    }
    return undefined;
  };

  const getPreferredRole = async (): Promise<{
    roles: Role[];
    preferredRole?: Role;
  }> => {
    const roles = await getRoles();
    const urlRole = getUrlRole();
    if (urlRole && roles.includes(urlRole)) {
      return { roles: roles, preferredRole: urlRole };
    }
    if (roles.length === 0) {
      return { roles: roles, preferredRole: roles[0] };
    }

    // Let the user choose a role
    return { roles: roles, preferredRole: undefined };
  };

  return { getRoles, getPreferredRole };
};

export default useRoles;
