import * as Yup from 'yup';

export const userSchema = Yup.object().shape({
  id: Yup.string(),
  givenName: Yup.string().min(
    2,
    'Le prénom doit contenir au moins 2 caractères'
  ),
  familyName: Yup.string().min(2, 'Le nom doit contenir au moins 2 caractères'),
  email: Yup.string()
    .required("L'email est requis")
    .email("L'email est invalide"),
  picture: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .url("L'URL de l'image est invalide"),
  phoneNumber: Yup.string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .matches(/^\d{10}$/, 'Le numéro de téléphone est invalide'),
});
export type UserFormType = Yup.InferType<typeof userSchema>;
