import { classNames } from '@/core/lib/utils';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { NavigationItem } from '@/shared/components/page-layout';
import { getPathAtRouteLevel } from '@/stores/slices/navigation';

interface DesktopDrawerProps {
  navigation: NavigationItem[];
}
export default function DesktopDrawer({
  navigation,
}: Readonly<DesktopDrawerProps>) {
  const location = useLocation();
  const isActive = (href: string, end: boolean): boolean => {
    if (end) {
      return matchPath({ path: href, end: true }, location.pathname) !== null;
    }
    const locationAtRouteLevel = getPathAtRouteLevel(location.pathname, href);
    if (!locationAtRouteLevel) return false;
    return (
      matchPath({ path: href, caseSensitive: false }, locationAtRouteLevel) !==
      null
    );
  };

  return (
    <div
      className={`hidden z-50 lg:fixed lg:inset-y-0 lg:flex lg:w-side-nav lg:flex-col shadow-sidebar`}
    >
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-secondary p-4 border-r border-[#E8E8E8]">
        <NavLink end to="/" className="flex h-12 shrink-0 items-center mr-auto">
          {/* Home button */}
          <img
            src="/images/boostaferme-logo-darkened-1.png"
            alt="Boostaferme"
            className="max-h-[3em] w-auto"
          />
        </NavLink>

        {/* Tabs */}
        <nav className="flex flex-1 flex-col">
          <ul className="space-y-1">
            {navigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  end
                  to={item.href}
                  key={item.name}
                  className={classNames(
                    'group flex items-center gap-x-2 rounded-md py-3 px-4 text-[#6E7581] text-sm font-medium transition-colors hover:bg-accent-foreground hover:text-primary-foreground',
                    isActive(item.href, item.end)
                      ? 'bg-accent text-background hover:text-accent'
                      : ''
                  )}
                >
                  {item.icon}
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
