import { useEffect, useState } from 'react';
import { NavigationItem } from '@/shared/components/page-layout';
import Header from './components/header';
import MobileDrawer from './components/mobile-drawer';
import DesktopDrawer from './components/desktop-drawer';
import Breadcrumb from '@/core/components/breadcrumb';
import { SIDE_NAV_WIDTH } from '@/core/types/constants';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/footer';

interface CollapsibleMenuProps {
  children: React.ReactNode;
  navigation: NavigationItem[];
  className?: string;
}
export default function CollapsibleMenu({
  children,
  navigation,
  className = '',
}: Readonly<CollapsibleMenuProps>) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigationTabs, setNavigationTabs] = useState<NavigationItem[]>([]);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    setNavigationTabs(navigation);
  }, [navigation]);

  return (
    <div
      style={
        {
          '--side-nav-width': SIDE_NAV_WIDTH,
        } as React.CSSProperties
      }
    >
      {navigationTabs.length > 0 && (
        <>
          <MobileDrawer
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            navigation={navigationTabs}
          />

          <DesktopDrawer navigation={navigationTabs} />
        </>
      )}

      <div
        className={`${navigationTabs.length > 0 ? 'lg:pl-side-nav' : ''} max-h-[100vh] h-[100vh] flex flex-col relative`}
      >
        <Header
          setSidebarOpen={setSidebarOpen}
          displayMenuIcon={navigationTabs.length > 0}
        />
        <main className={`${className} mt-12 p-6 flex flex-col w-full flex-1`}>
          {isAuthenticated && <Breadcrumb />}
          {children}
        </main>
        <Footer />
      </div>
    </div>
  );
}
