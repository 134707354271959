import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import Grant from '@/shared/models/grant';
import RichTextEditor from '@/core/components/rich-text-editor';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDate } from '@/core/lib/utils';

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Le nom est requis'),
  description: Yup.string().required('La description est requise'),
  submissionDeadline: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  maxBudget: Yup.number()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

interface EditProps {
  values: Grant;
  onUpdate: (values: Partial<Grant>) => void;
}

const Edit = forwardRef(({ values, onUpdate }: EditProps, ref) => {
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm(formOptions);

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((key) => {
        console.error(key, errors[key].message);
      });
    }
  }, [errors]);

  // Submit form handler
  const submitForm = handleSubmit((data) => {
    onUpdate(data as Partial<Grant>);
  });

  // Expose the submitForm function to the parent via the ref
  useImperativeHandle(ref, () => ({
    submitForm,
  }));

  return (
    <form onSubmit={submitForm} className="flex flex-col gap-4">
      <div className="flex flex-col mr-4">
        <label htmlFor="label" className="text-accent">
          Nom de l'aide
        </label>
        <input
          id="label"
          {...register('label')}
          defaultValue={values.label}
          className="w-1/2 input"
        />
        {errors.label && (
          <span className="text-red-500">{errors.label.message}</span>
        )}
      </div>

      <div className="flex gap-4">
        <div className="flex flex-col w-1/2">
          <label htmlFor="submissionDeadline" className="text-accent">
            Date limite de dépôt
          </label>
          <input
            id="submissionDeadline"
            type="date"
            {...register('submissionDeadline')}
            defaultValue={formatDate(values.submissionDeadline)}
            className="w-full input"
          />
          {errors.submissionDeadline && (
            <span className="text-red-500">
              {errors.submissionDeadline.message}
            </span>
          )}
        </div>

        <div className="flex flex-col w-1/2">
          <label htmlFor="maxBudget" className="text-accent">
            Budget maximum
          </label>
          <input
            id="maxBudget"
            type="number"
            {...register('maxBudget')}
            defaultValue={values.maxBudget || ''}
            className="w-full input"
          />
          {errors.maxBudget && (
            <span className="text-red-500">{errors.maxBudget.message}</span>
          )}
        </div>
      </div>

      <div>
        <label htmlFor="description" className="text-accent">
          Description
        </label>
        <input
          type="hidden"
          {...register('description', { value: values.description })}
        />
        <RichTextEditor
          content={values.description}
          onChange={(content) => {
            setValue('description', content);
          }}
        />
        {errors.description && (
          <span className="text-red-500">{errors.description.message}</span>
        )}
      </div>
    </form>
  );
});

export default Edit;
