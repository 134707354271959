export default interface User {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  customerId?: string;
  blocked: boolean;
  blockedFor?: string;
  email: string;
  emailVerified: boolean;
  familyName?: string;
  givenName?: string;
  name: string;
  nickname?: string;
  username: string;
  phoneNumber?: string;
  phoneVerified: boolean;
  picture?: string;
  subscriptionState:
    | 'active'
    | 'trialing'
    | 'past_due'
    | 'unpaid'
    | 'cancelled'
    | 'incomplete';
}

export function getUserName(user: User, fallbackToEmail?: boolean): string {
  if (user.givenName && user.familyName) {
    return `${user.familyName.toUpperCase()}, ${user.givenName}`;
  }
  if (user.nickname) return user.nickname;
  if (user.username) return user.username;
  if (fallbackToEmail) return user.email;
  return 'Utilisateur';
}
