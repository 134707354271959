import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import sanitizeHtml from 'sanitize-html';
import { format } from 'date-fns';
import User from '@/shared/models/user';
import { formatISO } from 'date-fns/formatISO';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function readFileAsync(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function truncate(str?: string, n: number = 30) {
  if (!str) return 'N/A';
  return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

type ClassName = string | boolean;
export function classNames(...classes: ClassName[]) {
  return classes.filter(Boolean).join(' ');
}

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};
export function sanitize(
  dirty?: string,
  options: sanitizeHtml.IOptions = {}
): { __html: string | TrustedHTML } {
  if (!dirty) return { __html: 'N/A' };
  const sanitized = sanitizeHtml(dirty, { ...defaultOptions, ...options });
  return { __html: sanitized };
}

export function isStringNullOrEmpty(str?: string | null) {
  return !str || str.trim() === '';
}

export function lightenColor(color: string, percentage: number) {
  // Function to lighten the color
  const num = parseInt(color.replace('#', ''), 16),
    amt = Math.round(2.55 * percentage),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
    (B < 255 ? (B < 1 ? 0 : B) : 255)
  )
    .toString(16)
    .slice(1)}`;
}

export function getRoleLabel(role: string | undefined) {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'advisor':
      return 'Conseiller';
    case 'farmer':
      return 'Agriculteur';
    case 'maintainer':
      return 'Rédacteur';
    default:
      return 'Utilisateur';
  }
}
export function formatDate(dateString: string | Date): string | undefined {
  if (!dateString || dateString === '') return;
  const date = dateString instanceof Date ? dateString : new Date(dateString);
  return formatISO(date, { representation: 'date' });
}

export function formatLocaleDate(
  dateString: string | Date
): string | undefined {
  if (!dateString || dateString === '') return;
  const date = dateString instanceof Date ? dateString : new Date(dateString);
  return format(date, 'dd/MM/yyyy');
}

export function getUserName(user: User) {
  if (user.givenName && user.familyName) {
    return `${user.givenName.toUpperCase()}, ${user.familyName}`;
  }
  if (user.nickname) return user.nickname;
  if (user.username) return user.username;
  return user.email;
}
