import { getRoleLabel, isStringNullOrEmpty, truncate } from '@/core/lib/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { MenuIcon, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useCurrentPreferredRole } from '@/stores/slices/user';
import Organizations from './organizations';
import { Dialog } from '@headlessui/react';
import { useNavigationActions } from '@/stores/slices/navigation';

interface HeaderProps {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayMenuIcon?: boolean;
}

export default function Header({
  setSidebarOpen,
  displayMenuIcon,
}: Readonly<HeaderProps>) {
  const [menuBarIcon, setMenuBarIcon] = useState(false);
  useEffect(() => {
    setMenuBarIcon(displayMenuIcon);
  }, [displayMenuIcon]);

  // update breadcrumb on route change
  const location = useLocation();
  const { setCurrentPath } = useNavigationActions();
  useEffect(() => {
    setCurrentPath({
      url: location.pathname,
    });
  }, [setCurrentPath, location.pathname]);

  const role = useCurrentPreferredRole();
  const [extendProfileDropdown, setExtendProfileDropdown] = useState(false);
  const { user, logout } = useAuth0();

  if (!user) return null;
  if (location.pathname.indexOf('/auth') !== -1) return null;

  async function handleLogout() {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  return (
    <div
      className={`fixed top-0 left-0 ${menuBarIcon ? 'lg:left-side-nav' : ''} right-0 z-40 border-b border-[#E8E8E8] bg-secondary`}
    >
      <div className="flex h-12 items-center gap-x-0 shadow-sm lg:shadow-none">
        {/* Menu icon */}
        {menuBarIcon && (
          <button
            type="button"
            className="px-4 text-gray-700 hover:bg-gray-100 lg:hidden h-full"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        )}

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <Organizations />

        {/* Profile dropdown */}
        <div className="flex items-center z-[1000] flex-grow justify-end h-full">
          <button
            className="my-auto px-4 py-2 text-gray-700 hover:bg-gray-100 h-full flex gap-4 items-center justify-center"
            onClick={() => setExtendProfileDropdown(!extendProfileDropdown)}
          >
            {user.avatar ? (
              <img
                className="h-6 w-6 bg-[#aaa] rounded-full cursor-pointer object-cover"
                src={user.avatar}
                aria-label="Avatar"
              />
            ) : (
              <img
                src={
                  isStringNullOrEmpty(user.picture)
                    ? '/icons/avatar.svg'
                    : user.picture
                }
                alt="Profil"
                className="profile__avatar rounded-full h-6 w-6 min-h-6 min-w-6"
                referrerPolicy="no-referrer"
              />
            )}
            <span className="text-[8px] text-[#888] uppercase">
              {getRoleLabel(role)}
            </span>
          </button>

          <Dialog
            transition
            className={`relative z-50 transition duration-300 ease-out data-[closed]:opacity-0`}
            open={extendProfileDropdown}
            onClose={setExtendProfileDropdown}
          >
            <div className="fixed top-0 right-0 flex flex-col shadow-md mr-4 py-0 rounded overflow-hidden bg-white mt-[3.2em]">
              <div
                className={`px-[10px] py-1.5 bg-secondary flex justify-between items-center gap-4`}
              >
                <div>
                  <div className="flex items-center gap-2 ml-4 md:ml-0">
                    <span className="block text-[15px] font-[Arial] text-[#333]">
                      {truncate(
                        `${user.firstname ?? ''} ${user.lastname ?? ''}`,
                        25
                      )}
                    </span>
                    <span className="block text-[15px] font-[Arial] text-primary">
                      {truncate(user?.email, 30)}
                    </span>
                  </div>
                </div>
                <button
                  type="button"
                  className="p-1.5 mr-0 text-gray-700 hover:bg-gray-100 rounded-full"
                  onClick={() => setExtendProfileDropdown(false)}
                >
                  <XIcon className="h-4 w-4 text-accent" aria-hidden="true" />
                </button>
              </div>

              <div
                className="flex flex-col"
                onClick={() => setExtendProfileDropdown(false)}
              >
                <div className="text-[15px] font-[Arial] p-[10px] text-left text-primary cursor-pointer border-l-4 border-[#ffffff] hover:border-[#4d90fb] hover:bg-[#d3bfc731] hover:text-[#4d90fb]">
                  <NavLink
                    className={`pl-8 md:pl-4 text-inherit hover:no-underline block`}
                    to="/profile"
                  >
                    Mon Compte
                  </NavLink>
                </div>
                <div
                  className="text-[15px] font-[Arial] p-[10px] text-left text-primary cursor-pointer border-l-4 border-[#ffffff] hover:border-red-400 hover:bg-[#d3bfc731] hover:text-[#F87171]"
                  onClick={handleLogout}
                >
                  <NavLink
                    style={{ textDecoration: 'none' }}
                    className={`pl-8 md:pl-4 text-inherit hover:no-underline block`}
                    to="#"
                  >
                    Déconnexion
                  </NavLink>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
