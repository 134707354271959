import Farmer from '@/shared/models/farmer';
import UserView from './item-view-user';
import FarmView from './item-view-farm';

const View = ({ values }: { values: Farmer }) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      <UserView user={values.User} />

      <div>
        <p className="text-lg font-semibold">Exploitations agricoles</p>
        <div className="flex flex-wrap gap-12">
          {values.Farms?.map((farm) => <FarmView key={farm.id} farm={farm} />)}
        </div>
      </div>
    </div>
  );
};

export default View;
