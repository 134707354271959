import React from 'react';

interface IconButtonProps {
  icon: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  startLabel?: string;
  endLabel?: string;
  onClick?: () => void;
  className?: string;
  padding?: string;
  disabled?: boolean;
  bgColor?: string;
  bgHoverColor?: string;
  fgColor?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  type = 'submit',
  startLabel,
  endLabel,
  onClick,
  className,
  padding = 'p-2',
  disabled,
  bgColor = 'accent',
  bgHoverColor = 'accent-foreground',
  fgColor = 'white',
}) => {
  return (
    <button
      className={`${className} p-2 group`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <div
        className={`${padding} rounded-full bg-${bgColor} hover:bg-${bgHoverColor} text-${fgColor} group-disabled:bg-gray-300 group-disabled:text-gray-500 font-semibold text-xs uppercase flex gap-4`}
      >
        {startLabel && <span>{startLabel}</span>}
        {icon}
        {endLabel && <span>{endLabel}</span>}
      </div>
    </button>
  );
};

export default IconButton;
