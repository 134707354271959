import { Role } from '@/core/types/role';
import Admin from '@/shared/models/admin';
import Advisor from '@/shared/models/advisor';
import AdvisorOrganization from '@/shared/models/advisor-organization';
import Farmer from '@/shared/models/farmer';
import { Maintainer } from '@/shared/models/maintainer';
import { create, type StateCreator } from 'zustand';

export type UserStore = {
  readonly preferredRole?: Role;
  readonly roles: Role[];

  // associated entities
  readonly advisorOrganization?: AdvisorOrganization;
  readonly advisorOrganizations: AdvisorOrganization[];
  readonly advisor?: Advisor;
  readonly farmer?: Farmer;
  readonly maintainer?: Maintainer;
  readonly admin?: Admin;
};

const initialState: UserStore = {
  preferredRole: undefined,
  roles: [],
  advisorOrganization: undefined,
  advisorOrganizations: [],
  advisor: undefined,
  farmer: undefined,
  maintainer: undefined,
  admin: undefined,
};

export type UserAction = {
  setPreferredRole: (role?: Role) => void;
  setRoles: (roles: Role[]) => void;
  setAdvisorOrganization: (advisorOrganization?: AdvisorOrganization) => void;
  setAdvisorOrganizations: (
    advisorOrganizations: AdvisorOrganization[]
  ) => void;
  setAdvisor: (advisor?: Advisor) => void;
  setFarmer: (farmer?: Farmer) => void;
  setMaintainer: (maintainer?: Maintainer) => void;
  setAdmin: (admin?: Admin) => void;
};

export const userStoreCreator: StateCreator<UserStore & UserAction> = (
  set
) => ({
  ...initialState,
  setPreferredRole: (role?: Role) => set(() => ({ preferredRole: role })),
  setRoles: (roles: Role[]) => set(() => ({ roles })),
  setAdvisorOrganization: (advisorOrganization?: AdvisorOrganization) =>
    set(() => ({ advisorOrganization })),
  setAdvisorOrganizations: (advisorOrganizations: AdvisorOrganization[]) =>
    set(() => ({ advisorOrganizations })),
  setAdvisor: (advisor?: Advisor) => set(() => ({ advisor })),
  setFarmer: (farmer?: Farmer) => set(() => ({ farmer })),
  setMaintainer: (maintainer?: Maintainer) => set(() => ({ maintainer })),
  setAdmin: (admin?: Admin) => set(() => ({ admin })),
});
const useUser = create(userStoreCreator);

// Custom hooks
export const useCurrentPreferredRole = () =>
  useUser((state) => state.preferredRole);
export const useIsAdmin = () =>
  useUser((state) => state.preferredRole) === 'admin';
export const useCurrentRoles = () => useUser((state) => state.roles);
export const useCurrentAdvisorOrganization = ():
  | AdvisorOrganization
  | undefined => useUser((state) => state.advisorOrganization);
export const useCurrentAdvisorOrganizations = () =>
  useUser((state) => state.advisorOrganizations);
export const useCurrentAdvisor = () => useUser((state) => state.advisor);
export const useCurrentFarmer = () => useUser((state) => state.farmer);
export const useCurrentMaintainer = () => useUser((state) => state.maintainer);
export const useCurrentAdmin = () => useUser((state) => state.admin);
export const useUserActions = () => useUser((state) => state);
