import { useUsers } from '@/core/hooks/use-user';
import { Invitation } from '@/core/types/invitation';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const Index = () => {
  const { error } = useAuth0();
  const { acceptInvitations } = useUsers();
  const [invitations, setInvitations] = useState<Invitation[]>([]);

  useEffect(() => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1)); // Removing the '#' from the hash

    const type = params.get('type');
    if (type !== 'invite') {
      return;
    }

    const roles = params.get('roles').split(',');
    const ids = params.get('ids').split(',');
    const paramsInvitations: Invitation[] = [];

    for (let i = 0; i < roles.length; i++) {
      paramsInvitations.push({
        role: roles[i] + 's',
        id: ids[i],
      } as Invitation);
    }
    setInvitations(paramsInvitations);

    acceptInvitations(paramsInvitations);
  }, []);

  if (error) {
    return (
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Error
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>{error.message}</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="page-layout">
      <div className="page-layout__content" />
      <h1>Invitations</h1>
      {invitations.map((invitation, index) => (
        <div key={index}>
          <h2>{invitation.role}</h2>
          <p>{invitation.id}</p>
        </div>
      ))}
    </div>
  );
};

export default Index;
