import configurationService from '@/core/services/configuration.service';
import FacebookIcon from '@/assets/icons/facebook.svg?react';
import XIcon from '@/assets/icons/x.svg?react';
import LinkedInIcon from '@/assets/icons/linkedin.svg?react';
import GithubIcon from '@/assets/icons/github.svg?react';
import { useApiStatus } from '@/core/hooks/use-api-status';
import { useEffect, useState } from 'react';
import { ApiStatus } from '@/core/types/api-status';

const sections = [
  {
    label: 'Produits',
    items: [
      {
        label: 'Notre offre',
        url: '/offer',
      },
      {
        label: 'Contact',
        url: '/contact',
      },
    ],
  },
  {
    label: 'Ressources',
    items: [
      {
        label: 'Blog',
        url: '/blog',
      },
      {
        label: 'Guides',
        url: '/guides',
      },
      {
        label: "Centre d'aide",
        url: '/help-center',
      },
      {
        label: 'Documentation',
        url: '/documentation',
      },
      {
        label: 'FAQs',
        url: '/faqs',
      },
    ],
  },
  {
    label: 'À propos',
    items: [
      {
        label: 'À propos de nous',
        url: '/about-us',
      },
      {
        label: "L'équipe",
        url: '/team',
      },
      {
        label: 'Carrière',
        url: '/career',
      },
      {
        label: 'Nouveautés',
        url: '/news',
      },
      {
        label: 'Presse',
        url: '/press',
      },
      {
        label: 'Média',
        url: '/media',
      },
    ],
  },
  {
    label: 'Légal',
    items: [
      {
        label: 'Politique de confidentialité',
        url: '/privacy',
      },
      {
        label: "Conditions d'utilisation",
        url: '/terms',
      },
      {
        label: 'Cookies',
        url: '/cookies',
      },
      {
        label: 'Licences',
        url: '/licenses',
      },
      {
        label: 'Contact',
        url: '/contact',
      },
    ],
  },
];

const marketing: {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  url: string;
}[] = [
  {
    icon: FacebookIcon,
    url: 'https://facebook.com',
  },
  {
    icon: LinkedInIcon,
    url: 'https://linkedin.com',
  },
  {
    icon: GithubIcon,
    url: 'https://github.com',
  },
  {
    icon: XIcon,
    url: 'https://x.com',
  },
];
export default function Footer() {
  const { getApiStatus } = useApiStatus();

  const [apiStatus, setApiStatus] = useState<ApiStatus | null>(null);

  useEffect(() => {
    (async () => {
      setApiStatus(await getApiStatus());
    })();
  }, []);

  return (
    <footer className="bg-white">
      <div className="h-full w-full">
        {/* Marketing */}
        <div className="mx-auto max-w-screen-xl h-full sm:flex sm:items-center sm:justify-between px-4 sm:px-6 lg:px-8">
          <div className="text-teal-600"></div>

          <ul className="h-full flex items-center justify-end gap-6 sm:mt-0 sm:justify-end">
            {marketing.map((item, index) => (
              <li key={index}>
                <a
                  href={item.url}
                  rel="noreferrer"
                  target="_blank"
                  className="text-gray-700 transition hover:opacity-75"
                >
                  <item.icon className="h-6 w-6" />
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Sections */}
        <div className="bg-white w-full border-t border-gray-100 p-0 pt-4 lg:pt-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-screen-xl">
            {/* Grid */}
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="mx-auto sm:mx-0 grid grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 !mt-0">
                {sections.map((section, index) => {
                  return (
                    <div key={index} className="text-center sm:text-left">
                      <p className="text-lg font-medium text-gray-900">
                        {section.label}
                      </p>
                      <ul className="space-y-4 text-sm">
                        {section.items.map((item, index) => {
                          return (
                            <li key={index}>
                              <a
                                className="text-gray-700 transition hover:text-gray-700/75"
                                href={item.url}
                              >
                                {item.label}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <QuestionForm className="text-center sm:text-left w-56 mx-auto" />
            </div>

            {/* Legal */}
            <div className="flex justify-center h-12 mt-8 border-t border-gray-100 sm:flex sm:items-center sm:justify-between">
              <p className="text-center text-sm text-gray-500 sm:text-left">
                Boostaferme &copy; 2024. Tous droits réservés.
              </p>

              {/* App & Api info */}
              <div className="flex justify-end text-xs text-gray-400 gap-4">
                <div className="flex gap-2">
                  {/* Frontend Version */}
                  <p>
                    APP v
                    {configurationService.configuration.version.replace(
                      'v',
                      ''
                    )}
                  </p>

                  {' | '}

                  {/* Backend Version */}
                  <p>API v{apiStatus?.version}</p>

                  {/* Environment ( Not shown in production ) */}
                  {configurationService.configuration.env !== 'Production' ? (
                    <>
                      {' | '}
                      <p>{configurationService.configuration.env}</p>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const QuestionForm = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <p className="text-lg font-medium text-gray-900">
        Une question?
        <br />
        Nous répondons sous 1 à 2 jours par email !
      </p>

      <div className="mx-auto mt-8 max-w-md sm:ms-0">
        <form className="mt-4">
          <div className="flex flex-col flex-wrap gap-4 sm:flex-row lg:flex-col lg:items-start">
            <label htmlFor="email" className="sr-only">
              Email
            </label>

            <input
              className="w-full rounded-full border-gray-200 px-6 py-3 shadow-sm"
              type="email"
              placeholder="Entrer votre email"
              readOnly
            />

            <label htmlFor="message" className="sr-only">
              Message
            </label>

            <textarea
              className="w-full rounded-lg border-gray-200 px-6 py-3 shadow-sm"
              placeholder="Bonjour, pouvez-vous m'aider à..."
              rows={5}
              readOnly
            />

            <button
              className="block rounded-full bg-accent px-8 py-3 font-medium text-white transition hover:bg-accent-foreground
                disabled:bg-gray-300 disabled:text-gray-500"
              type="submit"
              disabled
            >
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
