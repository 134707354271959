import { useNavigate } from 'react-router-dom';
import { ComponentType, FC, useState, useEffect } from 'react';
import useRoles from '@/core/hooks/use-roles';
import { Role } from '@/core/types/role';

export interface WithRoleBasedRedirectOptions {
  roles?: Role[];
}

export const withRoleBasedRedirect =
  <P extends object>(
    Component: ComponentType<P>,
    options: WithRoleBasedRedirectOptions
  ): FC<P> =>
  (props: P): JSX.Element => {
    const { getRoles } = useRoles();
    const navigate = useNavigate();
    const [isAuthorized, setIsAuthorized] = useState(false);
    useEffect(() => {
      async function checkRoles(requiredRoles: Role[]) {
        const roles = await getRoles();
        const isAuthorized = requiredRoles.some((role) => roles.includes(role));
        if (!isAuthorized) {
          navigate('/unauthorized');
          return;
        }
        setIsAuthorized(true);
      }
      if (!options.roles || options.roles.length === 0) {
        setIsAuthorized(true);
        return;
      }
      checkRoles(options.roles);
    }, [getRoles]);
    return isAuthorized ? <Component {...props} /> : <div></div>;
  };
