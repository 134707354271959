import IconButton from '@/core/components/controls/buttons/icon-button';
import ListPlaceholder from '@/core/components/controls/placeholders/list-placeholder';
import SearchBar from '@/core/components/controls/search-bar';
import PageLoader from '@/core/components/page-loader';
import Paginator from '@/core/components/paginator';
import { useFarmers } from '@/core/hooks/use-farmers';
import { isStringNullOrEmpty } from '@/core/lib/utils';
import FarmerParams from '@/core/types/farmerParams';

import Farmer from '@/shared/models/farmer';
import { RotateCcw, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentAdvisorOrganization } from '@/stores/slices/user';
import ListItem from './list-item';
import Create from './list-create-modal';

const getInitialFilter = (searchParams: URLSearchParams) => {
  const filter = searchParams
    ? FarmerParams.fromUrl(searchParams)
    : new FarmerParams();
  filter.include ||= 'User';
  filter.pageNumber ??= 0;
  filter.pageSize ||= 10;
  return filter;
};

const Index = () => {
  const navigate = useNavigate();

  const currentAdvisorOrganization = useCurrentAdvisorOrganization();

  // Fetch data
  const { searchFarmers } = useFarmers();

  // Manage search params
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(getInitialFilter(searchParams));
  const [hasFilter, setHasFilter] = useState(false);
  const [total, setTotal] = useState(0);

  // Manage state
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [farmers, setFarmers] = useState<Farmer[]>([]);

  const updateSearchParams = (filter: FarmerParams) => {
    const filtersObj = new FarmerParams();
    Object.assign(filtersObj, filter);
    const url = '?' + filtersObj.toUrl();
    window.history.pushState({}, '', url);
  };

  const get = async () => {
    updateSearchParams(filter);
    setIsLoading(true);

    const data = await searchFarmers(filter);
    const values = data.value?.data || [];
    filter.pageSize ??= 10;
    setFarmers(values);
    setTotal(data.value?.total ?? 0);
    if (!isInitialized) setIsInitialized(true);
    setIsLoading(false);
    setHasFilter(!isStringNullOrEmpty(filter.search));
  };

  // On enter key press, trigger search
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (isLoading) return;
      if (e.key !== 'Enter') return;
      get();
    };
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  // On component mount, fetch data
  useEffect(() => {
    if (!currentAdvisorOrganization) return;
    filter.organizationId = currentAdvisorOrganization.id;
    get();
  }, [currentAdvisorOrganization]);

  return (
    <>
      {/* Header */}
      <div className="flex flex-wrap justify-between items-start mb-4">
        <div className="flex flex-col gap-2">
          <h1>Vos agriculteurs</h1>
          <h2>Retrouvez tous les agriculteurs que vous conseillez</h2>
        </div>

        <div className="flex">
          <Create
            onCreated={async (farmer: Farmer) => {
              navigate(`/advisor/farmers/${farmer.id}?edit=true`);
            }}
          />
        </div>
      </div>

      {/* Filters */}
      {/* <div className="flex flex-wrap items-center gap-4">
        <SearchBar
          search={filter.search}
          setFilter={setFilter}
          className="border-none focus:border-solid shadow-md"
        />

        <div className="flex gap-4">
          <IconButton
            icon={<RotateCcw size={16} />}
            endLabel="Appliquer les filtres"
            onClick={get}
            fgColor="white"
            bgColor={`${isLoading ? 'gray-300' : 'accent'}`}
            disabled={isLoading || !isInitialized}
          />

          <IconButton
            icon={<X size={16} />}
            disabled={isLoading || !hasFilter}
            onClick={() => {
              setSearchParams('');
            }}
            fgColor="white"
            bgColor={`${isLoading ? 'gray-300' : 'accent'}`}
          />
        </div>
      </div> */}

      <div className="my-2" />

      {/* Table */}
      <div className="flex flex-1 overflow-x-hidden overflow-y-auto justify-center items-start">
        {!isInitialized ? (
          <ListPlaceholder />
        ) : (
          <FarmerList farmers={farmers} />
        )}
      </div>

      {/* Paginator */}
      <Paginator
        className="my-4"
        page={filter.pageNumber ?? 0}
        setPage={(page) => {
          filter.setPage(page);
          get();
        }}
        last={Math.ceil(total / (filter.pageSize ?? 1))}
        zeroIndexed
        isLoading={isLoading}
      />
      <PageLoader />
    </>
  );
};

interface Props {
  farmers: Farmer[];
}
// farmer list
const FarmerList = ({ farmers }: Props) => {
  return (
    <div className="pb-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-x-2 gap-y-6 justify-center max-w-full w-full">
      {farmers.length === 0 ? (
        <PageLoader />
      ) : (
        farmers.map((farmer) => <ListItem key={farmer.id} farmer={farmer} />)
      )}
    </div>
  );
};

export default Index;
