import PaginateParams from './paginateParams';

export default class GrantsParams extends PaginateParams {
  constructor(
    public tags?: string,
    public localizations?: string,
    public grantTypes?: string,
    params?: PaginateParams
  ) {
    super(
      params?.search,
      params?.sortBy,
      params?.order,
      params?.pageSize,
      params?.pageNumber,
      params?.columnFilters,
      params?.columnOrders,
      params?.include
    );
    this.tags = tags;
    this.localizations = localizations;
    this.grantTypes = grantTypes;
  }

  override toUrlParams(): URLSearchParams {
    const params = super.toUrlParams();
    if (this.tags) params.append('tags', this.tags);
    if (this.localizations) params.append('localizations', this.localizations);
    if (this.grantTypes) params.append('grantTypes', this.grantTypes);
    return params;
  }

  override toUrl(): string {
    const url = this.toUrlParams().toString();
    return url;
  }

  static fromUrl(urlParams: URLSearchParams): GrantsParams {
    const tags = urlParams?.get('tags') ?? undefined;
    const localizations = urlParams.get('localizations') ?? undefined;
    const grantTypes = urlParams.get('grantTypes') ?? undefined;
    return new GrantsParams(
      tags,
      localizations,
      grantTypes,
      PaginateParams.fromUrl(urlParams)
    );
  }

  copy(params: GrantsParams): void {
    super.copy(params);
    this.tags = params.tags;
    this.localizations = params.localizations;
    this.grantTypes = params.grantTypes;
  }
}
