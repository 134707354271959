import { readFileAsync } from '../lib/utils';
import { RepoResult } from '../types/repoResult';
import { useApi } from './use-api';

interface FileUploadParams {
  file: {
    rawBody: unknown;
    name: string;
  };
  folder: string;
}

interface MultipleFileUploadParams {
  files: Array<{
    rawBody: unknown;
    name: string;
  }>;
  folder: string;
}

export const useFiles = () => {
  const getApi = useApi();

  const uploadFile = async (file: globalThis.File, folder: string) => {
    const api = await getApi();

    const rawBody = await readFileAsync(file);
    const data = await api.post<FileUploadParams, RepoResult<string>>(
      `/files`,
      {
        file: { rawBody, name: file.name },
        folder,
      }
    );
    if (data.isFailure) throw data.error;
    return data.value;
  };

  const uploadFiles = async (files: globalThis.FileList, folder: string) => {
    const api = await getApi();
    const filesArray = Array.from(files);
    const rawBodies = await Promise.all(
      filesArray.map(async (file) => await readFileAsync(file))
    );
    const data = await api.post<MultipleFileUploadParams, RepoResult<string>>(
      `/files/multiple`,
      {
        files: rawBodies.map((rawBody, index) => ({
          rawBody,
          name: filesArray[index].name,
        })),
        folder,
      }
    );
    if (data.isFailure) throw data.error;
    return data.value;
  };

  return { uploadFile, uploadFiles };
};
