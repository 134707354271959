import { useGrants } from '@/core/hooks/use-grants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  SaveIcon,
  Eye,
  Pencil,
  ArchiveIcon,
  CopyIcon,
  DeleteIcon,
  ShareIcon,
} from 'lucide-react';
import IconButton from '@/core/components/controls/buttons/icon-button';
import { useNavigationActions } from '@/stores/slices/navigation';
import Loader from '@/core/components/loader';
import { useCurrentPreferredRole } from '@/stores/slices/user';
import ActionsMenu, {
  MenuAction,
} from '@/core/components/controls/buttons/actions-menu';
import View from './view';
import Edit from './edit';
import Grant from '@/shared/models/grant';
import GrantsParams from '@/core/types/grantsParams';

const params = new GrantsParams();
params.include = 'Tag,GrantType,Localization';

const editors = ['admin', 'maintainer'];

const Item = () => {
  const role = useCurrentPreferredRole();
  const allowEdit = useCallback(() => editors.includes(role), [role]);
  const { setCurrentPath } = useNavigationActions();
  const { id } = useParams();
  const { getGrant, patchGrant } = useGrants();
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState<Grant>();
  const [isLoading, setIsLoading] = useState(true);

  // Access edit form submit method via reference
  const editRef = useRef<{ submitForm: () => void }>(null);

  const setPath = (grantLabel: string) => {
    setCurrentPath({ label: grantLabel, url: window.location.pathname });
  };

  const fetchGrant = async () => {
    if (!id) return;
    setIsLoading(true);
    const data = await getGrant(id, params);
    setValues(data.value);
    setPath(data.value.label);
    setIsLoading(false);
  };

  const onUpdate = async (updatedValues: Partial<Grant>) => {
    try {
      await patchGrant({ ...updatedValues, id: id });
      toast.success('Mise à jour réussie');
      await fetchGrant();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchGrant();
  }, [id]);

  const getActions = useCallback(() => {
    const actions: MenuAction[] = [
      {
        label: 'Partager',
        startIcon: <ShareIcon size={16} color="#a78bfa" />,
        callback: () => {
          navigator.clipboard.writeText(
            `${window.location.origin}/grants/${id}`
          );
          toast.success('Lien copié dans le presse-papiers');
        },
      },
    ];
    if (allowEdit()) {
      actions.push(
        {
          label: 'Dupliquer',
          startIcon: <CopyIcon size={16} color="#a78bfa" />,
          callback: () => {},
          disabled: true,
        },
        {
          label: 'Archiver',
          startIcon: <ArchiveIcon size={16} color="#a78bfa" />,
          callback: () => {},
          disabled: true,
        },
        {
          label: 'Supprimer',
          startIcon: <DeleteIcon size={16} color="#a78bfa" />,
          callback: () => {},
          disabled: true,
        }
      );
    }
    return actions;
  }, [allowEdit, id]);

  if (!id)
    return (
      <div className="text-red-500">
        L'aide agricole recherchée n'existe pas.
      </div>
    );
  if (!values || isLoading) return <Loader size="large" />;

  return (
    <div className="relative flex-1 flex flex-col gap-4">
      <div className="absolute top-[-4px] right-0 left-0 flex flex-row items-center justify-end">
        {allowEdit() && (
          <>
            <IconButton
              icon={<SaveIcon size={14} />}
              type="submit"
              disabled={!isEditing}
              fgColor="white"
              bgColor={isEditing ? 'accent' : 'gray-300'}
              bgHoverColor={isEditing ? 'accent-foreground' : 'gray-300'}
              onClick={() => editRef.current?.submitForm()} // Call the submit handler from the Edit component
            />
            <IconButton
              onClick={() => setIsEditing(!isEditing)}
              icon={isEditing ? <Eye size={14} /> : <Pencil size={14} />}
              fgColor="white"
              bgColor={!isEditing ? 'accent' : 'primary'}
              bgHoverColor={!isEditing ? 'accent-foreground' : 'primary'}
            />
          </>
        )}
        <ActionsMenu float="left" actions={getActions()} />
      </div>

      {isEditing ? (
        <Edit ref={editRef} values={values} onUpdate={onUpdate} />
      ) : (
        <View values={values} />
      )}
    </div>
  );
};

export default Item;
