import useRoles from '@/core/hooks/use-roles';
import { Role } from '@/core/types/role';
import { useUserActions } from '@/stores/slices/user';
import { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';

const getRoleColor = (role: string) => {
  switch (role) {
    case 'admin':
      return '#FFB3B3'; // pastel red
    case 'farmer':
      return '#92e292'; // pastel green
    case 'advisor':
      return '#B3CFFF'; // pastel blue
    case 'maintainer':
      return '#D1B3FF'; // pastel purple
    default:
      return '#D3D3D3'; // pastel gray
  }
};

const getRoleLabel = (role: string) => {
  switch (role) {
    case 'admin':
      return 'Administrateur';
    case 'farmer':
      return 'Agriculteur';
    case 'advisor':
      return 'Conseiller';
    case 'maintainer':
      return "Rédacteur d'aides";
    default:
      return 'Inconnu';
  }
};

const Index = () => {
  const navigate = useNavigate();
  const { getRoles } = useRoles();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const { setPreferredRole } = useUserActions();

  useEffect(() => {
    (async () => {
      const roles = await getRoles();
      if (roles.length === 1) {
        navigate(`/${roles[0]}`);
        return;
      }
      setUserRoles(roles);
    })();
  }, [getRoles]);

  return (
    <div className="flex flex-col items-center mt-4">
      <h2 className="text-xl font-bold mb-4">Vos rôles</h2>
      <div className="grid grid-cols-2 gap-1 shadow-md p-4 rounded-md">
        {userRoles.map((role) => (
          <NavLink
            key={role}
            to={`/${role}`}
            onClick={() => setPreferredRole(role as Role)}
            style={
              {
                '--selected-color': getRoleColor(role),
              } as React.CSSProperties
            }
            className={`flex items-center justify-center h-32 w-32 rounded-md hover:bg-[--selected-color] group`}
          >
            <span
              className={`text-sm underline underline-offset-4 decoration-2 text-center text-black decoration-[--selected-color] group-hover:text-[#333] group-hover:decoration-white`}
            >
              {getRoleLabel(role)}
            </span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Index;
